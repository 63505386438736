import React from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import CanShowCalculationMiddleware from "../../misc/CanShowCalculationMiddleware";
import {Button, Badge, Input, TextArea, Select, Checkbox, Notification, Tabs, Tab} from "@appkit4/react-components";
import PagePanel from "../../misc/PagePanel";
import Centered from "../../misc/Centered";
import {useGlobalStore} from "../../stores/GlobalStore";
import {HeaderType} from "../../types/HeaderType";
import Layout from "../Layout/Layout";
import * as O from "fp-ts/Option";
import {useTr} from "../../utils/trUtil";
import {Loading} from "@appkit4/react-components/loading";
import "./AdjustmentEntries.scss";
import {Adjustment} from "../../types/Adjustment";
import {adjustment_types} from "../../Constants";
import {stripHtml} from "../../utils/stripHtml";
import {AdjustmentChildItem} from "./Adjustment/AdjustmentChildItem";
import {BackendUserInfo} from "../../types/UserInfo";
import {FilterList} from "./Adjustment/FilterList";
import {AdjustmentAttachments} from "./Adjustment/AdjustmentAttachments";
import {AdjustmentJournalEntries} from "./Adjustment/AdjustmentJournalEntries";
import PageNotFound from "../PageNotFound/PageNotFound";
import {AdjustmentCategory} from "../../types/AdjustmentCategory";
import i18n from "../../utils/i18n";
import BackButton from "../../misc/BackButton";
import {getCalculationBaseUrl} from "../../utils/userUtil";
import Confirmation from "../../misc/Confirmation";
import {AdjustmentSettings} from "./Adjustment/AdjustmentSettings";
import {TaxTreatmentType} from "../../types/TaxTreatmentTypes";

declare type PropTypes = {};

const AdjustmentDetail: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");
    const trtf = useTr("tax_flows");

    const params = useParams();

    const {
        calculation,
        entity,
        fetchApi,
        addErrorNotification,
        addSuccessNotification,
        categories,
        reviewers,
        getBackendUserInfo,
        codebooks,
        loadAdjustments,
        currentCalculationAdjustments,
    } = useGlobalStore((s) => ({
        calculation: s.currentCalculation,
        entity: s.currentCalculationEntity,
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
        addSuccessNotification: s.addSuccessNotification,
        categories: s.currentCalculationAdjustmentCategories,
        reviewers: s.currentCalculationReviewers,
        getBackendUserInfo: s.getBackendUserInfo,
        codebooks: s.codebooks,
        loadAdjustments: s.loadAdjustments,
        currentCalculationAdjustments: s.currentCalculationAdjustments,
    }));

    const gs = useGlobalStore((s) => s);

    const [id, setId] = React.useState<number>();
    const [adjustment, setAdjustment] = React.useState<Adjustment>();
    const [adjustmentAreLoaded, setAdjustmentAreLoaded] = React.useState<boolean>(false);
    const [reloadingData, setReloadingData] = React.useState<boolean>(false);

    const [name, setName] = React.useState<string>("");
    const [amount, setAmount] = React.useState<string>((0.0).toFixed(2));
    const [finalAmount, setFinalAmount] = React.useState<string>((0.0).toFixed(2));
    const [coefficient, setCoefficient] = React.useState<string>((1.0).toFixed(2));
    const [category, setCategory] = React.useState<number>();
    const [deferredTaxClassification, setDeferredTaxClassification] = React.useState<string>("");
    const [type, setType] = React.useState<string>("");
    const [officialComment, setOfficialComment] = React.useState<string>("");
    const [internalComment, setInternalComment] = React.useState<string>("");
    const [useGenericComment, setUseGenericComment] = React.useState<boolean>(true);
    const [subadjustments, setSubadjustments] = React.useState<Adjustment[]>([]);
    const [taxClassifications, setTaxClassifications] = React.useState<any[]>([]);

    const [dropdownCategories, setDropdownCategories] = React.useState<any[]>([]);

    const [formIsSubmitted, setFormIsSubmitted] = React.useState<boolean>(false);
    const [errorCategory, setErrorCategory] = React.useState<string>("");
    const [showRemoveApprovalWarning, setShowRemoveApprovalWarning] = React.useState<boolean>(true);
    const [pageNotFound, setPageNotFound] = React.useState<boolean>(false);

    const [activeIndex, setActiveIndex] = React.useState<number>();
    const [queryParams, setQueryParams] = useSearchParams();
    const onTabChange = (i: number, value: string) => {
        setQueryParams({tab_index: i + ""});
    };

    const navigate = useNavigate();

    const getAdjustmentBaseAPIUrl = React.useCallback(() => {
        return O.isSome(entity) && O.isSome(calculation)
            ? `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/`
            : "/";
    }, [entity, calculation]);

    const deleteAdjustment = React.useCallback(() => {
        if (adjustment && O.isSome(entity) && O.isSome(calculation)) {
            let url = `${getAdjustmentBaseAPIUrl()}${adjustment.id}/`;

            fetchApi(url, "delete")
                .then((data: any) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        gs.deleteAdjustmentFromCalculation(adjustment);
                        loadAdjustments();
                        navigate(`${getCalculationBaseUrl(entity, calculation)}/tax-flows/${adjustment.type}`);
                    }
                })
                .catch((err) => {
                    addErrorNotification(err);
                });
        }
    }, [
        adjustment,
        entity,
        calculation,
        fetchApi,
        addErrorNotification,
        loadAdjustments,
        getAdjustmentBaseAPIUrl,
        gs,
        navigate,
    ]);

    React.useEffect(() => {
        if (params.id) setId(parseInt(params.id));
    }, [params]);

    React.useEffect(() => {
        setAdjustmentAreLoaded(false);
        setReloadingData(false);
        if (queryParams.get("tab_index") === null) setQueryParams({tab_index: "0"});
    }, [queryParams, setQueryParams]);

    React.useEffect(() => {
        let idx = queryParams.get("tab_index");
        if (idx !== null) {
            setActiveIndex(parseInt(idx));
        }
    }, [queryParams]);

    React.useEffect(() => {
        if (
            O.isSome(entity) &&
            O.isSome(calculation) &&
            id &&
            (adjustmentAreLoaded === false || reloadingData === true)
        ) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/${id}/`)
                .then((res: any) => res.json())
                .then((res: TaxTreatmentType | any) => {
                    setReloadingData(false);
                    setAdjustmentAreLoaded(true);
                    if (typeof res.error !== "undefined") {
                        if (res.error.status_code === 404) setPageNotFound(true);
                        addErrorNotification(res);
                    } else {
                        setAdjustment(res);
                    }
                })
                .catch((err) => {
                    addErrorNotification(err);
                    setReloadingData(false);
                    setAdjustmentAreLoaded(true);
                });
        }
    }, [
        entity,
        calculation,
        id,
        reloadingData,
        adjustmentAreLoaded,
        setPageNotFound,
        setAdjustment,
        addErrorNotification,
        setAdjustmentAreLoaded,
        fetchApi,
        setReloadingData,
    ]);

    const setCurrentAdjustment = React.useCallback(
        (adjs: Adjustment[]) => {
            adjs.forEach((adj: Adjustment) => {
                if (adjustment && adj.id === adjustment.id) setAdjustment(adj);
                if (adj.subadjustments && adj.subadjustments.length > 0) setCurrentAdjustment(adj.subadjustments);
            });
        },
        [adjustment]
    );

    React.useEffect(() => {
        if (O.isSome(currentCalculationAdjustments)) setCurrentAdjustment(currentCalculationAdjustments.value);
    }, [currentCalculationAdjustments, setCurrentAdjustment]);

    React.useEffect(() => {
        if (categories.length > 0) {
            setDropdownCategories(
                categories
                    .map((group) => {
                        return {
                            label: group.name,
                            key: group.id,
                            type: "group",
                            children: group.categories
                                .map((category) => {
                                    return {
                                        key: category.id,
                                        value: category.id,
                                        label: category.name,
                                    };
                                })
                                .sort((a, b) => (a.label > b.label ? 1 : -1)),
                        };
                    })
                    .sort((a, b) => (a.label > b.label ? 1 : -1))
            );
        }
    }, [categories]);

    React.useEffect(() => {
        if (typeof codebooks.calculation.tax_classification !== "undefined") {
            setTaxClassifications(
                Object.entries(codebooks.calculation.tax_classification).map((value) => {
                    return {
                        label: value[1],
                        key: value[0],
                        value: value[0],
                    };
                })
            );
        }
    }, [codebooks]);

    React.useEffect(() => {
        if (adjustment) {
            let adj = adjustment;
            if (adj.type !== undefined) setType(adj.type);
            if (adj.name !== undefined) setName(stripHtml(adj.name));
            if (adj.amount !== undefined && adj.amount !== null)
                setAmount(typeof adj.amount === "string" ? parseFloat(adj.amount).toFixed(2) : adj.amount.toFixed(2));
            if (adj.final_amount !== undefined)
                setFinalAmount(
                    typeof adj.final_amount === "string"
                        ? parseFloat(adj.final_amount).toFixed(2)
                        : adj.final_amount.toFixed(2)
                );
            if (adj.coefficient !== undefined) setCoefficient(adj.coefficient.toFixed(2));
            if (adj.category !== undefined && adj.category !== null) setCategory(adj.category.id);
            if (adj.comment_official !== undefined) setOfficialComment(stripHtml(adj.comment_official));
            if (adj.comment_official_is_generic !== undefined) setUseGenericComment(adj.comment_official_is_generic);
            if (adj.comment_internal !== undefined)
                setInternalComment(stripHtml(adj.comment_internal ? adj.comment_internal : ""));
            if (adj.deferred_tax_classification !== undefined)
                setDeferredTaxClassification(adj.deferred_tax_classification);
            if (adj.subadjustments !== undefined) setSubadjustments(adj.subadjustments);
        }
    }, [adjustment]);

    // Detect if user remove approvals by editing adjustment
    React.useEffect(() => {
        let backendUserInfo = getBackendUserInfo();
        let userInfo: BackendUserInfo | null = O.isSome(backendUserInfo) ? backendUserInfo.value : null;
        if (userInfo) {
            let isR1 = reviewers.some(
                (reviewer) =>
                    userInfo &&
                    reviewer.user.email === userInfo.email &&
                    reviewer.claim_type === "user_entity:calculation:reviewer1"
            );

            let isR2 = reviewers.some(
                (reviewer) =>
                    userInfo &&
                    reviewer.user.email === userInfo.email &&
                    reviewer.claim_type === "user_entity:calculation:reviewer2"
            );

            switch (adjustment?.review_status) {
                case "reviewed":
                    setShowRemoveApprovalWarning(reviewers.length > 0 && isR2 !== true);
                    break;
                case "for_second_review":
                    setShowRemoveApprovalWarning(isR1 !== true && isR2 !== true);
                    break;
                default:
                    setShowRemoveApprovalWarning(false);
                    break;
            }
        }
    }, [reviewers, adjustment, getBackendUserInfo]);

    const initOfficialComment = React.useCallback(() => {
        let rel_cat = codebooks.categories
            ? codebooks.categories.find((c: AdjustmentCategory) => c.id === category)
            : undefined;
        setOfficialComment((s) =>
            rel_cat !== undefined
                ? i18n.language === "sk"
                    ? rel_cat.default_generic_comment_sk
                    : rel_cat.default_generic_comment_en
                : adjustment && adjustment.comment_official
                ? stripHtml(adjustment.comment_official)
                : ""
        );
    }, [adjustment, category, codebooks.categories]);

    React.useEffect(() => {
        if (useGenericComment) {
            initOfficialComment();
        }
    }, [useGenericComment, initOfficialComment]);

    React.useEffect(() => {
        setFinalAmount((parseFloat(amount) * parseFloat(coefficient)).toFixed(2));
    }, [coefficient, amount]);

    const dataAreInvalid = () => {
        if (!category && type !== adjustment_types.HELPFUL && adjustment) {
            setErrorCategory(trg("category_is_required"));
            return true;
        }
        setErrorCategory("");
        return false;
    };

    const useGenericCommentHandler = (v: any) => {
        setUseGenericComment(v);
    };

    const saveHandler = () => {
        if (dataAreInvalid()) return;

        let url = getAdjustmentBaseAPIUrl();
        if (url === "") return;

        setFormIsSubmitted(true);

        let endpoint = adjustment ? `${url}${adjustment.id}/` : `${url}`;
        let method = adjustment ? "patch" : "post";

        let basic_body = {
            name: name,
            amount: amount,
            type: type,
            coefficient: parseFloat(coefficient),
            category: category,
            comment_official: officialComment,
            comment_official_is_generic: useGenericComment,
            comment_internal: internalComment,
            deferred_tax_classification: deferredTaxClassification,
        };

        let body = adjustment ? basic_body : {...basic_body};

        fetchApi(endpoint, method, body)
            .then((response) => response.json())
            .then((data) => {
                setFormIsSubmitted(false);
                setAdjustmentAreLoaded(true);
                if (typeof data.error !== "undefined") {
                    addErrorNotification(data);
                    loadAdjustments();
                } else {
                    setAdjustment(data);
                    loadAdjustments();
                    addSuccessNotification(trg("saved_successfully"));
                }
            })
            .catch((data) => {
                addErrorNotification(data);
                loadAdjustments();
                setFormIsSubmitted(false);
                setAdjustmentAreLoaded(true);
            });
    };

    const adjustmentForm = adjustment ? (
        <>
            <div className="row">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-12 mt-3 pt-3">
                            <Select
                                placeholder={trg("category")}
                                data={dropdownCategories}
                                value={category}
                                error={errorCategory === "" ? false : true}
                                searchable={true}
                                dropdownMatchWidth={false}
                                required={type !== adjustment_types.HELPFUL}
                                disabled={
                                    (adjustment.attributes &&
                                        adjustment.attributes.category_condition &&
                                        adjustment.attributes.category_condition.length > 0) ||
                                    type === adjustment_types.CASH_BASIS ||
                                    type === adjustment_types.PROVISIONS ||
                                    type === adjustment_types.VALUATION_ALLOWANCES
                                }
                                onSelect={(v: any) => {
                                    setCategory(v);
                                    setErrorCategory("");
                                }}
                            />
                        </div>
                        <div className="col-md-12 mt-3">
                            <Select
                                placeholder={trg("deferred_tax_classification")}
                                //hideTitleOnInput={true}
                                data={taxClassifications}
                                value={deferredTaxClassification}
                                dropdownMatchWidth={false}
                                disabled={
                                    type === adjustment_types.CASH_BASIS ||
                                    type === adjustment_types.PROVISIONS ||
                                    type === adjustment_types.VALUATION_ALLOWANCES
                                }
                                onSelect={(v: any) => setDeferredTaxClassification(v)}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-md-7">
                            <Checkbox
                                checked={useGenericComment}
                                disabled={
                                    type === adjustment_types.PROVISIONS ||
                                    type === adjustment_types.VALUATION_ALLOWANCES
                                }
                                onChange={useGenericCommentHandler}
                            >
                                {trg("use_generic_comment")}
                            </Checkbox>
                        </div>
                        <div className="col-md-12 mt-2">
                            <TextArea
                                title={trg("official_comment")}
                                value={officialComment}
                                readonly={
                                    type === adjustment_types.PROVISIONS ||
                                    type === adjustment_types.VALUATION_ALLOWANCES
                                }
                                onChange={(v) => setOfficialComment(stripHtml(v))}
                            />
                        </div>
                        <div className="col-md-12 mt-2">
                            <TextArea
                                title={trg("internal_comment")}
                                value={internalComment}
                                readonly={
                                    type === adjustment_types.PROVISIONS ||
                                    type === adjustment_types.VALUATION_ALLOWANCES
                                }
                                onChange={(v) => setInternalComment(stripHtml(v))}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-3 d-flex">
                <div className="flex-grow-1">
                    <Confirmation
                        buttonKind="negative"
                        buttonText={trg("delete_adjustment")}
                        buttonStyle={{marginRight: "10px"}}
                        onConfirm={deleteAdjustment}
                    />
                </div>

                <Button onClick={saveHandler} kind="primary" loading={formIsSubmitted}>
                    {trg("save_adjustment")}
                </Button>
            </div>
        </>
    ) : (
        <></>
    );

    if (pageNotFound) {
        return <PageNotFound />;
    }

    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <PagePanel
                    title={
                        <>
                            <small>{`${name ? name : ""}`}</small>
                            <sup>
                                {adjustment && (
                                    <Badge
                                        className="ms-3"
                                        value={trtf(`${adjustment.type}_label`)}
                                        type={"primary-outlined"}
                                    />
                                )}
                            </sup>
                        </>
                    }
                    className="adjustment-page"
                    buttons={
                        <BackButton
                            url={
                                O.isSome(entity) && O.isSome(calculation) && adjustment
                                    ? adjustment.parent
                                        ? `/calculation/${entity.value.hash}/${
                                              calculation.value.hash
                                          }/tax-flows/adjustment/${
                                              typeof adjustment.parent === "object"
                                                  ? adjustment.parent.id
                                                  : adjustment.parent
                                          }?tab_index=1`
                                        : `/calculation/${entity.value.hash}/${calculation.value.hash}/tax-flows/${adjustment.type}`
                                    : undefined
                            }
                        />
                    }
                >
                    {adjustmentAreLoaded && adjustment ? (
                        <>
                            <div className="row">
                                <div className="col-md-12">
                                    {reloadingData && (
                                        <Loading loadingType="linear" indeterminate={true} compact={false}></Loading>
                                    )}
                                    {showRemoveApprovalWarning && (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <Notification
                                                    className="fullheader warning"
                                                    title=""
                                                    message={<div>{trg("this_adjustment_is_reviewed")}</div>}
                                                    status="warning"
                                                ></Notification>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row border border-1 border-dark p-1 py-3 ">
                                <div className="col-md-6">
                                    <Input
                                        type="text"
                                        title={trg("adjustment_name")}
                                        value={name}
                                        readonly={
                                            type === adjustment_types.FIXED_ASSETS ||
                                            type === adjustment_types.PROVISIONS ||
                                            type === adjustment_types.VALUATION_ALLOWANCES
                                        }
                                        onChange={(v: string) => setName(stripHtml(v))}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="row align-items-center">
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                value={amount}
                                                readonly={type !== adjustment_types.MANUAL}
                                                title={trg("amount")}
                                                className="form-control-number"
                                                onChange={(v: string) => setAmount(v)}
                                            />
                                        </div>
                                        <div className="col-md-1 text-center">x</div>
                                        <div className="col-md-3">
                                            <Input
                                                type="text"
                                                title={trg("coefficient")}
                                                maxLength={5}
                                                className="form-control-number"
                                                value={coefficient}
                                                onChange={(v: string) => {
                                                    let number = parseFloat(v).toFixed(7);
                                                    if (number.length <= 10) setCoefficient(v);
                                                    else setCoefficient((s) => s);
                                                }}
                                            />
                                        </div>
                                        <div className="col-md-1 text-center">=</div>
                                        <div className="col-md-4">
                                            <Input
                                                type="text"
                                                className="form-control-number"
                                                value={finalAmount}
                                                readonly={true}
                                                title={trg("final_amount")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <Tabs type="underline" activeIndex={activeIndex} onTabChange={onTabChange}>
                                        <Tab label={trg("detail")} value="detail">
                                            {adjustmentForm}
                                        </Tab>
                                        {subadjustments.length > 0 ? (
                                            <Tab label={trg("subadjustments")} value="subadjustments">
                                                <>
                                                    {subadjustments.map((a) => {
                                                        return <AdjustmentChildItem key={a.id} adjustment={a} />;
                                                    })}
                                                    <div className="m-1 py-2 px-4 border text-end">
                                                        <small>{trg("result_formula")}&nbsp;&nbsp;&nbsp;&nbsp;</small>
                                                        <span>
                                                            {adjustment.attributes?.result_formula}
                                                            &nbsp;&nbsp;=&nbsp;&nbsp;
                                                            <strong>
                                                                {adjustment.final_amount
                                                                    ? adjustment.final_amount.toFixed(2)
                                                                    : "?"}
                                                            </strong>
                                                        </span>
                                                    </div>
                                                </>
                                            </Tab>
                                        ) : (
                                            ""
                                        )}
                                        {[adjustment_types.JOURNAL, adjustment_types.ACCOUNTING_RESULT].includes(
                                            adjustment.type
                                        ) ? (
                                            <Tab label={trg("filters")} value="filters">
                                                <FilterList adjustment={adjustment} />
                                            </Tab>
                                        ) : (
                                            ""
                                        )}
                                        {[adjustment_types.JOURNAL, adjustment_types.ACCOUNTING_RESULT].includes(
                                            adjustment.type
                                        ) ? (
                                            <Tab label={trg("journal_entries")} value={trg("journal_entries")}>
                                                <AdjustmentJournalEntries adjustment={adjustment} />
                                            </Tab>
                                        ) : (
                                            ""
                                        )}
                                        <Tab label={trg("attachments")} value="attachments">
                                            <AdjustmentAttachments adjustment={adjustment} />
                                        </Tab>
                                        <Tab label={trg("settings")} value="settings">
                                            <div className="d-flex">
                                                <div className="flex-grow-1"></div>
                                                <div className="col-md-6 col">
                                                    <AdjustmentSettings adjustment={adjustment} />
                                                </div>
                                                <div className="flex-grow-1"></div>
                                            </div>
                                        </Tab>
                                        <Tab label={trg("Formula")} value="formula">
                                            <div className="row text-muted">
                                                <div className="col-md-6 m-5 col">
                                                    {adjustment.attributes?.result_formula}
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Centered>
                            <Loading loadingType="linear" indeterminate={true} compact={false}></Loading>
                        </Centered>
                    )}
                    {reloadingData && (
                        <div className="row mt-3">
                            <div className="col text-center">
                                <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                            </div>
                        </div>
                    )}
                </PagePanel>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default AdjustmentDetail;
