import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";

const todo = "TODO: Translate";
i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // TODO: Rename resource languages to correct standard, eg. en-US, cz-CS
        // we init with resources
        resources: {
            en: {
                global: {
                    calculation: "Calculation",
                    current_calculation: "Current Calculation",
                    prior_calculation: "Prior calculation",
                    prior_calculation_current_rate: "Prior calculation with current rate",
                    add_entity: "Add entity",
                    category_is_required: "Category is required",
                    deferred_tax_classification: "Deferred tax classification",
                    coefficient: "Coefficient",
                    final_amount: "Final amount",
                    this_is_only_a_preview: "* This is only a preview data from selected sheet name",
                    edit_entity_info: "Edit entity info",
                    edit_data: "Edit data",
                    name_info: "Name info",
                    description: "Description",
                    cancel: "Cancel",
                    save: "Save",
                    saved_successfully: "Saved successfully.",
                    close: "Close",
                    confirm: "Confirm",
                    confirm_deleting_file: "Please confirm deleting this file.",
                    confirm_file_deletion: "Confirmation of file deletion",
                    confirm_unassigning_attachment_of_adjustment:
                        "Do you really want to unassign attachment of adjustment?",
                    replace_attachment_title: "Replacing an existing attachment",
                    replace_attachment_instruction:
                        "Be careful! This action is irreversible and will automatically replace the existing file with a new one.",
                    replace_attachment_new_vertion_title: "Replace existing attachment with new version",
                    permanently_delete_attachment_title: "Permanently delete this attachment",
                    delete_attachment_description:
                        "This file below will be permanently deleted from system and from all assigned adjustments:",
                    filename: "File name",
                    created: "Created",
                    assigned_adjustments: "Assigned adjustments",
                    account_number: "Account number",
                    account_number_1: "Account number 1",
                    account_number_2: "Account number 2",
                    opening: "Opening ",
                    balance: "Balance",
                    table: "Table",
                    tb: "TB",
                    diff: "Diff",
                    difference: "Difference",
                    movement: "Movement",
                    deselect_all: "deselect all",
                    select_all: "select all",
                    action_on_selected: "Action on selected:",
                    back: "Back",
                    account: "Account",
                    type: "Type",
                    opening_balance: "Opening Balance",
                    creation: "Creation",
                    release: "Release",
                    closing_balance: "Closing Balance",
                    check: "Check",
                    internal: "Internal",
                    comments: "Comments",
                    confirm_split: "confirm split",
                    split: "split",
                    actions: "Actions",
                    list_of_adjustments_is_empty: "List of adjustments is empty",
                    list_of_entries_is_empty: "List of entries is empty",
                    list_of_filters_is_empty: "List of filters is empty",
                    create_adjustment_from_trial: "Create adjustments from Trial Balance",
                    create_new_filter: "Create new filter",
                    update: "Update",
                    new_account: "new account",
                    official_comment: "Official comment",
                    internal_comment: "Internal comment",
                    creation_comment: "Creation comment",
                    release_comment: "Release comment",
                    reinit_default_adjustments: "reinit default adjustments",
                    show_entries: "Show entries",
                    go_to_adjustment_entries: "Show entries",
                    category: "Category",
                    clients: "Clients",
                    entites: "Entities",
                    settings: "Settings",
                    users: "Users",
                    firstname: "First Name",
                    lastname: "Last Name",
                    fullname: "Full Name",
                    name: "Name",
                    email_adress: "Email adress",
                    add_user: "Add user",
                    please_enter_a_valid_email_adress: "Please enter a valid email address",
                    where: "Where",
                    delete_filter_criterion: "delete filter criterion",
                    and: "and",
                    list_of_transactions_by_filter: "List of transactions by filter",
                    posting_date: "Posting Date",
                    document_no: "Document No.",
                    amount: "Amount",
                    debit_amount: "Debit Amount",
                    credit_amount: "Credit Amount",
                    assigned_to: "Assigned to",
                    list_is_empty: "LIst is empty",
                    upload_previously: "Upload previously exported adjustments entries",
                    trial_balance_reconciliation: "Trial Balance Reconciliation",
                    edit: "Edit",
                    add_new_adjustment: "add new adjustment",
                    attachments: "Attachments",
                    subadjustments: "Subadjustments",
                    detail: "Detail",
                    journal_entries: "Journal Entries",
                    add: "add",
                    or_you_can_select: "or you can select & assing existing attachments",
                    no_attachments: "No attachments",
                    delete_filter: "Delete filter",
                    save_filter: "Save filter",
                    delete_adjustment: "Delete adjustment",
                    delete_entry: "Delete entry",
                    save_adjustment: "Save adjustment",
                    this_adjustment_is_reviewed:
                        "This adjustment is reviewed. If you update it sign-of will be removed.",
                    use_generic_comment: "Use generic comment",
                    filters: "Filters",
                    filter: "Filter",
                    reset_filter: "Reset filter",
                    action: "Action",
                    list_of_transactions_by_filters: "List of transactions by filters",
                    next: "Next",
                    adjustment_name: "Adjustment Name",
                    name_of_calculation_is_empty: "Name of calculation is empty",
                    select_starting_period_date: "Select starting period date",
                    select_ending_period_date: "Select ending period date",
                    calculation_was_successfully_created:
                        "Calculation was successfully created. Now, you can add reviewers.",
                    delete_this_entity_info: "Delete this entity info",
                    tax_calculation_xlsx: "Tax calculation.xlsx",
                    tax_form_pdf: "Tax form.pdf",
                    tax_form_xml: "Tax form.xml",
                    memory_reports_xls: "Memory reports.xls",
                    tdt_figures_na: "TDT figures.n/a",
                    apply_filter: "display result",
                    filter_name: "Filter Name",
                    or: "or",
                    preparing: "Preparing...",
                    hide_completeness_with_zero: "Hide completeness with zero",
                    continue: "Continue",
                    balancing_figures: "Balancing Figures",
                    compl_balanced: "Compl. balanced",
                    journal_is_currently_loading: "Journal is currently loading. Next, you can edit",
                    trial_balance_adjustments: "trial balance adjustments",
                    note_the_first_row_of: "Note: The first row of the selected sheet has to contain column names.",
                    import_remove_total_rows_and_others:
                        "Remove all total or subtotal rows and other rows that do not represent relevant import items too.",
                    account_name: "Account name",
                    debit: "Debit",
                    credit: "Credit",
                    trial_balance_is_currently_loading: "Trial balance is currently loading. You can import journal",
                    import_journal: "import journal",
                    now: "now.",
                    list_of_reviewers_is_empty: "List of reviewers is empty.",
                    upload_previously_exported_updated: "Upload previously exported updated file",
                    export: "Export",
                    import: "Import",
                    tags: "Tags",
                    add_new_tag_for_account: "Add new tag for account number:",
                    create: "Create",
                    entity_item: "Entity item",
                    access_denied: "Access denied",
                    you_do_not_have_permission:
                        "You do not have permission to access this page. Please contact the administrator.",
                    add_new_user: "Add a new user",
                    user_email_address: "User email address",
                    delete_user: "Delete user",
                    user_of_this_client: "user of this client?",
                    please_enter_a_valid_email: "Please enter a valid email address",
                    yes_delete: "Yes, delete",
                    are_you_sure_you_really: "Are you sure you really want to delete",
                    user_of_this_entity: "user of this entity?",
                    a: "a",
                    ok: "Ok",
                    please_fill_all_the_fields: "Please fill all the fields below.",
                    please_wait: "Please, wait",
                    a_new_entity_is_being_created: "A new entity is being created. This action may take a few minutes.",
                    entity_name: "Entity name",
                    street: "Street",
                    street_number: "Street number",
                    city: "City",
                    country: "Country",
                    zip_code: "Zip code",
                    id_number: "ID number",
                    tax_number: "TAX number",
                    type_of_legacy: "Type of legacy",
                    search_by: "Search by",
                    from: "from",
                    to: "to",
                    upload_new_attachment: "Upload new attachment",
                    upload_attachment_instruction:
                        "You can upload any files in any format with a maximum file size of up to 1 GB.",
                    drag_and_drop_or: "Drag and drop or",
                    choose_files: "choose file",
                    generate_creation_release_per: "Generate creation release per OBCB",
                    generate_net_creation_or: "Generate NET creation or release per OBCB",
                    add_new_entry: "add new entry",
                    add_new_prior_entry: "add new prior entry",
                    export_entries_to: "Export entries to .XLSX",
                    import_entries_from: "Import entries from .XLSX",
                    entries: "Entries",
                    of_which_it_is: "of which it is",
                    edit_adjustment_entry: "Edit adjustment entry",
                    form: "Form",
                    ruleset: "Ruleset",
                    trial_balance: "Trial Balance",
                    journal_entry: "Journal",
                    tax_type: "Tax Type",
                    deffered_tax_entry_info: "Entry info",
                    book_value: "Book Value",
                    tax_value: "Tax Value",
                    rate: "Rate",
                    tax: "Calculated Tax",
                    value_adjustment: "Value Adjustment",
                    tax_recognised: "Tax Recognised",
                    deferred_tax_prior_entries: "Prior entries",
                    deferred_tax_current_entries: "Current entries",
                    deferred_tax_result: "Result",
                    accounting_result: "Accounting result",
                    journal_adjustments: "Journal adjustments",
                    manual_adjustments: "Manual adjustments",
                    taxable_result_adjustments: "Taxable result",
                    fixed_assets: "Fixed assets",
                    provisions: "Provisions",
                    valuation_allowances: "Valuation allowances",
                    cash_basis: "Cash basis",
                    helpful: "Calculated adjustments",
                    required_fields: "Required fields",
                    fill_account_numbers_with_zeros: "Fill account numbers with zeros",
                    adjustment_type: "Adjustment type",
                    adjustment_settings_change_type_description: "You can change adjustment type here:",
                    deferred_tax_settings: "Deferred tax settings",
                    deferred_tax_rate_opening: "Deferred tax rate at opening",
                    deferred_tax_rate_closing: "Deferred tax rate at closing",
                    manual_entry: "Manual entry",
                    total: "Total",
                    subtotal: "Subtotal",
                    assets: "Assets",
                    liabilities: "Liabilities",
                    equal_to: "equal to",
                    not_equal_to: "not equal to",
                    less_than: "less than",
                    greater_than: "greater than",
                    less_than_or_equal_to: "less than or equal to",
                    greater_than_or_equal_to: "greater than or equal to",
                    contains: "contains",
                    not_contains: "not contains",
                    begins_with: "begins with",
                    ends_with: "ends with",
                    not_begins_with: "not begins with",
                    not_ends_with: "not ends with",
                    first_import_journal:
                        "First you need to import a journal entries. Continue to `import journal` and try again.",
                    import_settings: "Import settings",
                    remove_white_space_1st_account: "Remove whitespace (1st account)",
                    remove_white_space_2nd_account: "Remove whitespace (2nd account)",
                    zero_padding_1st_account:
                        "Applying zero prefix padding to equalize account number length (1st account)",
                    zero_padding_2nd_account:
                        "Applying zero prefix padding to equalize account number length (2st account)",
                    use_combination_of_1st_2nd_account:
                        "Use combination of 1st and 2nd account as 1st account if only their combination is unique",
                    example_short: "ex.",
                    skip_import: "skip import",
                    shows_only_first_100_from: "shows only first 100 from",
                    shows_only_first: "shows only first",
                    description_en: "description EN",
                    description_sk: "description SK",
                    info: "info",
                    info_en: "info EN",
                    row_number: "row number",
                    formula: "formula",
                    xml_node_name: "xml node name",
                    xml_node_type: "xml node type",
                    source_data: "source data",
                    print: "print",
                    priority_xml: "priority xml",
                    priority_form: "priority form",
                    fixed_assets_depreciation: "Depreciation of fixed assets",
                    fixed_asset_disposal_by_sale: "Long-term assets disposed of by sale",
                    fixed_asset_disposal_by_scrapping_liquidation: "Long-term assets discarded by physical liquidation",
                    damaged_or_stolen_assets: "Property discarded due to damage or stolen property",
                    questionnaire: "Questionnaire",
                    paste_from_clipboard: "Paste from clipboard",
                    select_from_attachments: "Select from attachments",
                    search: "Search",
                    select_all: "Select All",
                    yes: "Yes",
                    no: "No",
                    fixed_intangible_assets: "Fixed intangible assets",
                    low_value_fixed_tangible_assets: "Low-value fixed tangible assets",
                    fixed_tangible_assets: "Fixed tangible assets (excluding low-value tangible assets)",
                    total_assets: "Total assets",
                    accounting_depreciation: "Accounting depreciation",
                    tax_depreciation: "Tax Deprecation",
                    interrupted_depreciation_of_tangible_assets: "Interrupted depreciation of tangible assets",
                    unused_part_of_fixed_asset_depreciation_in_the_year_of_capitalisation:
                        "Unused part of fixed asset depreciation in the year of capitalisation",
                    difference_between_tax_and_accounting_depreciation:
                        "Difference between tax and accounting depreciation",
                    accounting_net_book_value: "Accounting net book value",
                    tax_net_book_value: "Tax net book value",
                    revenue_from_sale: "Revenue from sale",
                    difference_between_tax_and_accounting_value: "Difference between tax and accounting value",
                    tax_net_book_value_of_individual_assets_up_to_compensation_received:
                        "Tax net book value of individual assets up to compensation received",
                    tax_net_book_value_of_individual_assets_exceeding_compensation_received:
                        "Tax net book value of individual assets exceeding compensation received",
                    compensation_received_for_individual_damaged_assets_exceeding_tax_book_value:
                        "Conmpensation received for individual damaged assets exceeding tax book value",
                    total_tax_net_book_value: "Total tax net book value",
                    total_compensation_received: "Total compensation received",
                    funding_information: "Funding information",
                    sum_earnings: "Sum earnings",
                    records_type: "Records type",
                    wealth_number: "Wealth number",
                    realistic_value: "Realistic value",
                    high_cost: "High cost",
                    opening_date: "Opening date",
                    closing_date: "Closing date",
                    number: "Number",
                    income: "Income",
                    percent_cost: "Percent cost",
                    calculations: "Calculations",
                    cost: "Cost",
                    sum_sponsors: "Sum sponsors",
                    cislo_suvahoveho_uctu_opravnej_polozky_spolocnosti:
                        "Balance sheet number of the company's correction item",
                    druh_opravnej_polozky: "Type of repair item",
                    zaciatocny_stav_opravnej_polozky: "Initial status of correction item",
                    tvorba_opravnej_polozky: "Creating correction item",
                    dlznik_zakaznik: "Debtor customer",
                    cost_high: "Cost high",
                    reward: "Reward",
                    reason: "Reason",
                    damages: "Damages",
                    refund_amount: "Refund Amount",
                    replacements: "Replacements",
                    details: "Details",
                    limits: "Limits",
                    treatments: "Treatments",
                    sum: "Sum",
                    bill: "Bill",
                    dph: "VAT",
                    phone_number: "Phone number",
                    operations: "Operations",
                    analysis: "Analysis",
                    hours: "Hours",
                    student: "Student",
                    copy_contract: "Copy contract",
                    return: "Return",
                    type_of_transaction: "Type of transaction",
                    date: "Date",
                    asset_number: "Asset number",
                    asset_description: "Asset description",
                    fair_value_of_the_asset: "Fair value of the asset",
                    costs: "Costs",
                    asset_name: "Asset name",
                    percent_of_deduction: "Percent of deduction",
                    internal_document_number: "Internal document number",
                    document_date: "Document date",
                    narrative_of_transaction: "Narrative of transaction",
                    respective_pl_account: "Respective PL account",
                    identification_of_costs: "Identification of costs",
                    amount_of_the_respective_payment: "Amount of respective payment",
                    amount_of_related_expenses: "Amount of related expenses",
                    questionId: "Questions IDs",
                    enter_value: "Enter a value",
                    logs: "Logs",
                    no_content: "No content for",
                    add_attachment: "Add an attachment from the list / Upload a new attachment",
                    unselect_attachment: "This action will unselect the attachment for the current question",
                    date_format: "mm/dd/yyyy",
                    pick_a_date: "Pick a date",
                    PwC_prepared_tax_calculation_and_tax_return_for_preceding_tax_period:
                        "PwC prepared tax calculation and tax return for preceding tax period",
                    recognised_on_the_companys_balance_sheet: "Recognised on the companys balance sheet",
                    leased_under_operative_leasing_agreement: "Leased under operative leasing agreement",
                    no_comments_yet: "No comments for this answer yet.",
                    no_attachments_yet: "No attachments for this answer yet",
                    add_new_comment: "Add a new comment",
                    write_comment: "Write a comment",
                    your_comment: "Your comment",
                    add_comment: "Add comment",
                    assign_question: "Assign this question:",
                    clear_date: "Clear date",
                    filter_by_keyword: "Filter by keyword",
                    flagged: "Flagged",
                    close_filters: "Close filters",
                    clear_filters: "Clear filters",
                    filter_error: "No matches were found for this filter criteria",
                    submit: "Submit",
                    important: "Important",
                    allow_sorting: "Allow sorting",
                    sorting_allowed: "Sorting allowed",
                    limit_of_entries_per_page: "Limit of entries per page",
                    comment_official_generic: "Comment official is generic",
                    deferred_tax_classification: "Deferred tax classification",
                    parent: "Parent",
                    category_condition: "Category condition",
                    id: "ID",
                    adjustment_name_en: "Adjustment name en",
                    name_sk: "Name SK",
                    category_group: "Category group",
                    default_generic_comment_en: "Default generic comment en",
                    default_generic_comment_sk: "Default generic comment sk",
                    priority: "Priority",
                    for_release: "For Release",
                    for_creation: "For Creation",
                    for_adjustment_entry_tax_type: "For adjustment entry tax type",
                    increase_tax_base: "Increase Tax Base",
                    calculation_step: "Calculation step",
                    default_deferred_tax_classification: "Default Deferred Tax Classification",
                    language: "Language",
                    ruleset_category_id: "Ruleset category ids",
                    row_type: "Row type",
                    html_type: "HTML type",
                    data_type: "Data type",
                    input_type: "Input type",
                    calculate_with: "Calculate with",
                    print_with: "Print with",
                    show: "Show",
                    help_text_en: "Help text EN",
                    help_text_sk: "Help text SK",
                    question_views: "Question views",
                    clear_selected: "Clear selected",
                    attachments_upload_allowed: "Attachments upload allowed",
                    please_separate: "Please separate the options with a comma without a space.",
                    file_templates: "File templates",
                    add_new_template: "Add new template",
                    list_of_templates: "List of templates",
                    choices: "Choices",
                    attachment_allowed: "Attachment allowed",
                    views: "Views",
                    equivalent_id: "Equivalent ID",
                    version: "Version",
                    select_the_relevant: "Select the relevant questionnaire to view its questions.",
                    tax_form: "Tax form",
                    adjustment_category_for: "Adjustment Category for rulesets",
                    selected_ruleset: "Selected Ruleset",
                    category_name_sk: "Category name SK",
                    category_name_en: "Category name EN",
                    country_key: "Country key",
                    clear_valid_to: "clear valid to datetime",
                    valid_from: "Valid from",
                    valid_to: "Valid to",
                    adjustment_entry_tax_type: "Adjusment entry tax type",
                    adjustment_entry_type: "Adjustment Entry Type",
                    valid: "Valid",
                    ruleset_form: "Ruleset form",
                    tag: "Tag",
                    type: "Type",
                    type_sk: "Type SK",
                    tag_id: "Tag ID",
                    creation_category_id: "Creation category id",
                    release_category_id: "ID kategórie vydania",
                    timing: "Timing",
                    select_form: "Select form",
                    select_questionnaire: "Select questionnaire",
                    select_ruleset: "Select ruleset",
                    category_id: "Category ID",
                    ruleset_id: "Ruleset ID",
                    copy_selected_form: "Copy selected form",
                    confirm_and_copy: "Confirm & Copy",
                    new_name: "New Name",
                    new_name_sk: "New Name SK",
                    new_xsd_schema_link: "New XSD Schema Link",
                    new_xsd_schema_path: "New XSD Schema Path",
                    download_json: "Download _all.json",
                    download_attachments: "Download attachments",
                    export_questionnaire: "Export",
                    fixtures: "Fixtures",
                    add_and_update_initial: "Add & Update initial rulesets",
                    go: "Go",
                    add_and_update_initial_ruleset_forms: "Add & Update initial ruleset forms",
                    add_and_update_initial_form_rows: "Add & Update initial form rows",
                    add_and_update_questions_in_questionnaires: "Add & Update Questions in questionnaires",
                    add_and_update_category_groups: "Add & Update category groups",
                    add_and_update_category: "Add & Update category",
                    add_and_update_ruleset_category_pairs: "Add & Update Ruleset Category pairs",
                    add_and_update_treatment_types: "Add & Update Treatment types",
                    add_and_update_tags_for_rulesets: "Add & Update Tags for Rulesets",
                    add_and_update_tag: "Add & Update Tag",
                    add_and_update_adjustment_default: "Add & Update Adjustment default",
                    add_and_update_adjustment_default_ruleset: "Add & Update Adjustment default ruleset",
                    admin_dashboard: "Admin Dashboard",
                    add_and_update_initial_data_as_ruleset:
                        "Add & Update initial data as ruleset, forms, categories, default adjustments,...",
                    here_you_can_upload_new_attachment:
                        "Here you can upload new attachment. The max file size is 1 GB.",
                    refresh: "refresh",
                    some_filters_can_be_deleted: "some filters can be deleted,",
                    some_adjustment_entries_can: "some adjustment entries can be added or deleted,",
                    some_subadjustment_can_be_added: "some subadjustment can be added or deleted",
                    if_you_change_adjustment_type: "If you change adjustment type:",
                    are_you_sure_you_want_to_delete: "Are you sure you want to delete this filter?",
                    account_2nd: "Account 2nd",
                    description_2nd: " Description (2nd)",
                    entry_date: "Entry date",
                    document_no_external: "Document No. External",
                    document_no_clearing: "Document No. Clearing ",
                    order_no: "Order No.",
                    offsetting_account_number: "Offsetting Account Number",
                    offsetting_account_number_2: "Offsetting Account Number 2",
                    partner_name: "Partner Name",
                    period: "Period",
                    posting_key: "Posting Key",
                    posting_type: "Posting Type",
                    profit_centre: "Profit Centre",
                    cost_centre: "Cost Centre",
                    project: "Project",
                    tax_code: "Tax Code",
                    tax_code_2: "Tax Code 2",
                    tax_date: "Tax Date",
                    field: "Field",
                    operator: "Operator",
                    select_tag: "Select tag",
                    please_enter_a_name: "Please enter a name",
                    current_prior: "current - prior",
                    current_prior_with_current: "current - prior with current rate",
                    please_enter_a_deferred_tax_rate: "Please enter a deferred tax rate opening",
                    please_enter_a_deferred_tax_rate_closing: "Please enter a deferred tax rate closing",
                    enter_the_required_information: "Enter the required information",
                    result_formula: "Result formula:",
                    all_the_inputs_below_are: "All the inputs below are automatically saved when changed",
                    select_relevant_form_for: "Select relevant form for exporting",
                    select_relevant_questionnaire: "Select relevant questionnaire",
                    selectrelevant_questionnaire_view: "Select relevant questionnaire view",
                    questionnaire_view: "Questionnaire view",
                    download: "Download",
                    edit_tax_form: "Edit Tax Form",
                    select_field: "Select Field",
                    is: "is",
                    select_operator: "Select Operator",
                    add_filter: "Add Filter",
                    journal_column_mapping: "Journal column mapping",
                    import_trial_balance: "Import trial balance",
                    list_of_questions_is_empty: "List of questions is empty",
                    all: "All",
                    for_review: "For Review",
                    for_second_review: "For Second Review",
                    in_progress: "In Progress",
                    trial_balance_was_downloaded: "Trial balance was downloaded",
                    tag_name: "Tag name",
                    order: "Order",
                    fullfilled: "Fullfilled",
                    no_client_was_found: "No client was found",
                    add_member: "Add Member",
                    access_to_the_requested: "403 - Access to the requested resource is forbidden!",
                    add_new_entity: "Add new entity",
                    signing_you_in: "Signing you in...",
                    signing_you_out: "Signing you out...",
                    login: "Login",
                    you_can_wait_here_until: "You can wait here until it gets back online.",
                    if_you_refresh: "If you refresh, you're likelly to lose your progress.",
                    are_you_sure_you_want_to_delete:
                        "Are you sure you want to delete this item? This action is permanent.",
                    delete: "Delete",
                    sorry_something_is_wrong: "Sorry, something is wrong",
                    its_nice_to_have_you_back: "It's nice to have you back,",
                    by_clicking_the_button_below:
                        "By clicking the button below, you will be redirected to log in under your",
                    log_in: "Log in",
                },
                base: {
                    welcome: "Hello World",
                    next: "Next",
                    back: "Back",
                },
                header: {
                    CalculationInfoLabel: "Settings",
                    SummaryLabel: "Summary",
                    EntityInfoLabel: "Entity Notes",
                    QuestionnaireLabel: "Questionnaire",
                    ImportLabel: "Import",
                    TrialBalanceLabel: "Trial Balance",
                    TaxFlowsLabel: "Tax Adjustments",
                    TaxBaseLabel: "Tax Base",
                    TaxLabel: "Tax",
                    ReviewLabel: "Review",
                    AttachmentsLabel: "Attachments",
                    DeferredTaxLabel: "Deferred Tax",
                    ExportsLabel: "Exports",
                    CalculationAttachmentsLabel: "Attachments of calculation",
                    FilterLabel: "Filter",
                    AdjustmentLabel: "Adjustment",
                    AdjustmentEntriesLabel: "Adjustment Entries",
                    name: "Name",
                    category: "Category",
                    tax_classification: "Tax Classification",
                    comment: "Comment",
                    filters: "Filters",
                    amount: "Amount",
                    coefficient: "Coefficient",
                    review_status: "Review status",
                },
                client_overview: {
                    LayoutTitle: "My Clients",
                    ArchivedLabel: "Archived",
                    new_calculation: "new calculation",
                    entity_count: "entities",
                    entity_count_2_4: "entities",
                    entity_count_1: "entity",
                    new_client: "new client",
                },
                client_create: {
                    CreateLabel: "Create new client",
                    Name: "Client name",
                },
                footer: {
                    copyrightText:
                        "All rights reserved. Definition: PwC refers to the Slovak member firm and may sometimes refer to the PwC network. <br />Each member firm is a separate legal entity. Please see <a class='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a> for further details.<br /><br />",
                    TOSLabel: "Terms of Use and Legal Notices",
                    TOSLink: todo,
                    PrivacyLabel: "Privacy and Cookies",
                    PrivacyLink: todo,
                    DataControllerLabel: "Data Controller",
                    DataControllerLink: todo,
                },
                calculation_base: {
                    tag: "Tag",
                    account_number: "Account Number",
                    account: "Account",
                    account_2nd: "Account (2nd)",
                    account_name: "Account Name",
                    opening_balance: "Opening Balance",
                    amount: "Amount",
                    net_movement: "Net Movement",
                    debit: "Debit",
                    credit: "Credit",
                    closing_balance: "Closing Balance",
                    document_no: "Document No.",
                    document_no_external: "Document No. External",
                    document_no_clearing: "Document No. Clearing ",
                    document_date: "Document Date",
                    posting_date: "Posting Date",
                    description: "Description",
                    amount_in_doc_curr: "Amount in Doc. Curr.",
                    cost_centre: "Cost Centre",
                    credit_amount: "Credit Amount",
                    debit_amount: "Debit Amount",
                    description_2nd: " Description (2nd)",
                    document_currency: "Document Currency",
                    document_type: "Document Type",
                    entry_date: "Entry date",
                    order_no: "Order No.",
                    offsetting_acct_no: "Offsetting Acct. No.",
                    offsetting_acct_no_2nd: "Offsetting Acct. No. (2nd)",
                    partner_name: "Partner Name",
                    period: "Period",
                    posting_key: "Posting Key",
                    posting_type: "Posting Type",
                    profit_centre: "Profit Centre",
                    project: "Project",
                    tax_code: "Tax Code",
                    tax_code_2: "Tax Code 2",
                    tax_date: "Tax Date",
                    choose_type: "Choose the type of data load",
                    replace: "replace",
                    old_entries: "- old entries will be removed and new added",
                    increment: "Increment all entries",
                    all_entries: "- all entries will be only added",
                    edit_data: "Edit data",
                    official_comment: "Official comment",
                    as: "as",
                    debit_credit_net_movement: "Debit & Credit or Net Movement",
                },
                calculation_create: {
                    label: "New calculation",
                    name: "Calculation name",
                    type: "Calculation type",
                    language: "Calculation language",
                    previousCalculation: "Previous calculation",
                    selectDesiredLanguage: "Select desired language",
                    selectCalculationType: "Select calculation type",
                    selectCalculation: "Select calculation",
                    internalDescription: "Internal description",
                    reviewers_1: "First reviewers",
                    reviewers_2: "Second reviewers",
                    saveAndClose: "Save & Close",
                    createAndNext: "Create & Next",
                    cancel: "Cancel",
                },
                calculation_import: {
                    title: "Import",
                    pleaseMapColumns: "Please map your columns",
                    account: "Account",
                    description: "Description",
                    amount: "Amount",
                    debit: "Debit",
                    credit: "Credit",
                    continue: "Continue",
                    total_movement: "Total movement:",
                    import_is_loaded: "Import is loaded",
                    import_is_running: "Import is running",
                    select_sheet: "Select sheet name",
                },
                tax_flows: {
                    "accounting-result_label": "Accounting Result",
                    journal_label: "Journal Adjustments",
                    manual_label: "Manual Adjustments",
                    taxable_result_label: "Taxable Result Adjustments",
                    fixed_assets_label: "Fixed Assets",
                    provisions_label: "Provisions",
                    valuation_allowances_label: "Valuation Adjustments",
                    cash_basis_label: "Cash Basis",
                    helpful_label: "Calculated Adjustments",
                    name: "Name",
                    category: "Category",
                    tax_classification: "Tax Classification",
                    comment: "Comment",
                    filters: "Filters",
                    amount: "Amount",
                    coefficient: "Coefficient",
                    actions: "Actions",
                    accounting_result: "Accounting result",
                    journal_adjustments: "Journal Adjustments",
                    manual_adjustments: "Manual Adjustments",
                    taxable_result_adjustments: "Taxable Result Adjustments",
                    fixed_assets: "Fixed Assets",
                    provisions: "Provisions",
                    valuation_allowances: "Valuation Adjustments",
                    cash_basis: "Cash Basis",
                    helpful: "Calculated Adjustments",
                },
                form: {
                    typeHere: "Type here...",
                    typeEmail: "Type email here...",
                    periodRange: "Period range",
                    selectPeriodDateRange: "Select period range",
                },
                settings: {
                    ClientSpecificLabel: "Client settings",
                    EntitySpecificLabel: "Entity settings",
                    users: "Users",
                    name: "Name",
                    email_adress: "Email address",
                    entities: "Entities",
                    add_user: "Add user",
                    cancel: "Cancel",
                    enter_valid_email: "Please enter a valid email address",
                    yes_delete: "Yes, delete",
                    are_you_sure: "Are you sure you really want to delete",
                    are_you_sure2: "user of this client",
                },
                entity_info: {
                    label: "Entity info",
                    name: "Name",
                    description: "Description",
                    actions: "Actions",
                    add_new_info: "add new info",
                },
                Import_trial_balance_adjustmentForm: {
                    add_new_account: "add new account",
                    account_number: "Account number",
                    account_name: "Account name",
                    description: "Description",
                    amount: "Amount",
                    add_new: "add new",
                },
                adjustment_form: {
                    add_adjustment: "add adjustment",
                },
                calculation_list: {
                    name: "Name",
                    preiod_start: "Period start",
                    period_end: "Period end",
                    status: "Status",
                    description: "Description",
                },
                import: {
                    import_trial_balance: "Import trial balance",
                    trial_balance_column_mapping: "Trial balance column mapping",
                    import_journal: "Import journal",
                    journal_column_mapping: "Journal column mapping",
                    trial_balance_adjustments: "Trial balance adjustments",
                    data_check: "Data check",
                    import_steps: "Import",
                    upload_instruction: "You can upload XLS or XLSX or CSV files. The max file size is 1 GB.",
                },
                tax_base: {
                    calculation_of_tax_base: "Calculation of the tax base",
                    increasing_items: "Increasing items",
                    decreasing_items: "Decreasing items",
                    subtotal: "Subtotal",
                    tax_base: "Tax Base",
                },
                export_base: {
                    export_types: "Export types",
                    tax_calculation: "Tax calculation.xlsx",
                    tax_form_pdf: "Tax form.pdf",
                    tax_form_xml: "Tax form.xml",
                    memory_reports: "Memory reports.xls",
                    tdt_figures: "TDT figures.n/a",
                },
            },
            sk: {
                global: {
                    calculation: "Kalkulácia",
                    current_calculation: "Aktuálna kalkulácia",
                    prior_calculation: "Predošlá kalkulácia",
                    prior_calculation_current_rate: "Predošla kalkulácia s aktuálnou sadzbou",
                    add_entity: "Pridať entitu",
                    category_is_required: "Zvoľte kategóriu",
                    deferred_tax_classification: "Klasifikácia odloženej dane",
                    coefficient: "Koeficient",
                    final_amount: "Finálna suma",
                    "Delete entries": "Vymazať položky",
                    this_is_only_a_preview: "* Toto je iba náhľad časti údajov zo zvoleného hárku",
                    edit_entity_info: "Úprava informácie o entite",
                    name_info: "Názov informácie",
                    description: "Popis",
                    cancel: "Zrušiť",
                    save: "Uložiť",
                    saved_successfully: "Úspešne uložené.",
                    close: "Zavrieť",
                    confirm: "Potvrdiť",
                    confirm_deleting_file: "Prosím, potvrďte vymazanie súboru.",
                    confirm_file_deletion: "Potvrdenie vymazania súboru",
                    confirm_unassigning_attachment_of_adjustment:
                        "Naozaj si želáte odstrániť priradenie prílohy k danej úprave?",
                    replace_attachment_new_vertion_title: "Nahradiť existujúcu prílohu novou verziou",
                    permanently_delete_attachment_title: "Natrvalo odstrániť prílohu",
                    delete_attachment_description:
                        "Súbor nižšie bude natrvalo odstránený zo systému a zo všetkých priradených úprav:",
                    filename: "Názov súboru",
                    created: "Dátum vytvorenia",
                    assigned_adjustments: "Priradené úpravy",
                    account_number: "Číslo účtu",
                    account_number_1: "Číslo účtu 1",
                    account_number_2: "Číslo účtu 2",
                    opening: "Počiatočný",
                    balance: "Zostatok",
                    table: "Tabuľky",
                    tb: "Hlavná kniha",
                    diff: "Rozdiel",
                    difference: "Rozdiel",
                    movement: "Pohyb",
                    deselect_all: "odznačiť všetko",
                    select_all: "vybrať všetko",
                    action_on_selected: "Vykonať akciu:",
                    back: "Späť",
                    account: "Účet",
                    type: "Typ",
                    opening_balance: "Počiatočný stav",
                    creation: "Tvorba",
                    release: "Čerpanie",
                    closing_balance: "Konečný stav",
                    check: "Kontrola",
                    internal: "Interné",
                    comments: "Komentáre",
                    confirm_split: "potvrdiť rozdelenie",
                    split: "rozdeliť",
                    actions: "Akcie",
                    list_of_adjustments_is_empty: "Zoznam úprav je prázdny",
                    list_of_entries_is_empty: "Zoznam položiek je prázdny",
                    list_of_filters_is_empty: "Zoznam filtrov je prázdny",
                    create_adjustment_from_trial: "Vytvoriť úpravy z hlavnej knihy",
                    create_new_filter: "Vytvoriť nový filter",
                    update: "Aktualizovať",
                    new_account: "nový",
                    creation_comment: "Komentár k tvorbe",
                    release_comment: "Komentár k čerpaniu",
                    reinit_default_adjustments: "obnoviť predvolené úpravy",
                    show_entries: "Zobraziť záznamy",
                    go_to_adjustment_entries: "Prejdite na položky úprav",
                    category: "Kategória",
                    clients: "Klienti",
                    entites: "Entity",
                    settings: "Nastavenie",
                    users: "Používatelia",
                    firstname: "Meno",
                    lastname: "Priezvisko",
                    fullname: "Meno a priezvisko",
                    name: "Názov",
                    email_adress: "Emailová adresa",
                    edit_data: "Upraviť údaje",
                    add_user: "Pridať používateľa",
                    please_enter_a_valid_email_adress: "Prosím, zadajte platnú emailovú adresu.",
                    yes_delete: "Áno, vymazať",
                    where: "Kde",
                    delete_filter_criterion: "odstrániť kritérium filtra",
                    and: "a",
                    list_of_transactions_by_filter: "Zoznam transakcií na základe filtra",
                    posting_date: "Dátum odoslania",
                    document_no: "Číslo dokumentu",
                    amount: "Suma",
                    debit_amount: "Debetná suma",
                    credit_amount: "Kreditná suma",
                    assigned_to: "Priradený",
                    list_is_empty: "Zoznam je prázdny",
                    upload_previously: "Nahratie naposledy exportovaných položiek úprav.",
                    trial_balance_reconciliation: "Rekonsiliácia na hlavnú knihu",
                    edit: "Upraviť",
                    add_new_adjustment: "Pridať novú úpravu",
                    attachments: "Prílohy",
                    subadjustments: "Podúpravy",
                    detail: "Detail",
                    journal_entries: "Položky denníka",
                    add: "pridať",
                    or_you_can_select: "alebo zvoliť z existujúcich príloh",
                    no_attachments: "Žiadne prílohy",
                    delete_filter: "Vymazať filter",
                    save_filter: "Uložiť filter",
                    delete_adjustment: "Vymazať úpravu",
                    delete_entry: "Vymazať položku",
                    save_adjustment: "Uložiť úpravu",
                    this_adjustment_is_reviewed:
                        "Táto úprava už je odsúhlasená. Jej následnou úpravou bude odsúhlasenie zrušené.",
                    use_generic_comment: "Použiť generický komentár",
                    filters: "Filtre",
                    filter: "Filter",
                    reset_filter: "resetovať filter",
                    action: "Akcia",
                    list_of_transactions_by_filters: "Zoznam transakcií na základe filtrov",
                    next: "Ďalej",
                    adjustment_name: "Názov úpravy",
                    name_of_calculation_is_empty: "Názov kalkulácie je prázdny",
                    select_starting_period_date: "Zvoľte dátum začiatku obdobia",
                    select_ending_period_date: "Zvoľte dátum konca obdobia",
                    calculation_was_successfully_created:
                        "Kalkulácia bola vytvorená. Teraz môžete pridať kontrolujúcich.",
                    delete_this_entity_info: "Odstrániť danú info o entite",
                    tax_calculation_xlsx: "Tax calculation.xlsx",
                    tax_form_pdf: "Tax form.pdf",
                    tax_form_xml: "Tax form.xml",
                    memory_reports_xls: "Memory reports.xls",
                    tdt_figures_na: "TDT figures.n/a",
                    apply_filter: "zobraziť výsledky",
                    filter_name: "Názov filtra",
                    or: "alebo",
                    preparing: "Pripravujeme...",
                    hide_completeness_with_zero: "Skryť riadky kontroly s nulou",
                    continue: "pokračovať",
                    balancing_figures: "Balancing Figures",
                    compl_balanced: "Compl. balanced",
                    journal_is_currently_loading: "Denník sa práve načítava. Ako ďalej môžete editovať",
                    trial_balance_adjustments: "úpravy nad hlavnou knihou",
                    note_the_first_row_of: "Poznámka: Prvý riadok zvoleného zošita musí obsahovať názvy stĺpcov.",
                    import_remove_total_rows_and_others:
                        "Poznámka:  Odstráňte tiež všetky súčtové, či medzisúčtové riadky a ďalšie riadky, ktoré nereprezentujú relevantné položky importu.",
                    account_name: "Názov účtu",
                    debit: "Debet",
                    credit: "Kredit",
                    trial_balance_is_currently_loading:
                        "Hlavná kniha sa práve načítava. Môžete pokračovať v importovaní denníka.",
                    import_journal: "Import denníka",
                    list_of_reviewers_is_empty: "Zoznam kontrolórov je prázdny.",
                    upload_previously_exported_updated: "Nahrať naposledy exportovaný a aktualizovaný súbor",
                    export: "Export",
                    import: "Import",
                    official_comment: "Oficiálny komentár",
                    internal_comment: "Interný komentár",
                    tags: "Tagy",
                    add_new_tag_for_account: "Pridať nový tag k účtu:",
                    create: "Vytvoriť",
                    entity_item: "Položka entity",
                    access_denied: "Prístup zamietnutý",
                    you_do_not_have_permission:
                        "Nemáte oprávnenie zobraziť danú stránku. Kontaktujte prosím administrátora.",
                    add_new_user: "Pridať nového užívateľa",
                    user_email_address: "Emailová adresa užívateľa",
                    delete_user: "Odstrániť užívateľa",
                    as: "ako",
                    user_of_this_client: "užívateľa daného klienta?",
                    please_enter_a_valid_email: "Prosím, zadajte platnú emailovú adresu",
                    are_you_sure_you_really: "Naozaj si želáte odstrániť",
                    user_of_this_entity: "ako užívateľa tejto entity?",
                    a: "a",
                    ok: "Ok",
                    please_fill_all_the_fields: "Prosím, vyplňte všetky povinné polia.",
                    please_wait: "Prosím, čakajte",
                    a_new_entity_is_being_created:
                        "Nová entita sa práve vytvára. Prosím, čakajte na dokončenie tejto úlohy.",
                    entity_name: "Názov entity",
                    street: "Ulica",
                    street_number: "Číslo ulice",
                    city: "Mesto",
                    country: "Krajina",
                    zip_code: "PSČ",
                    id_number: "IČO",
                    tax_number: "DIČ",
                    type_of_legacy: "Typ účtovania",
                    search_by: "Vyhľadať podľa",
                    from: "od",
                    to: "do",
                    upload_new_attachment: "Nahrať novú prílohu",
                    upload_attachment_instruction:
                        "Môžete nahrať akékoľvek súbory v ľubovoľnom formáte, pričom maximálna veľkosť súboru môže byť až 1 GB.",
                    drag_and_drop_or: "Ťahajte a pustite alebo",
                    choose_files: "vyberte súbor",
                    generate_creation_release_per: "Generovať tvorbu alebo uvoľnenie na OBCB",
                    generate_net_creation_or: "Generovať NET tvorbu alebo uvoľnenie na OBCB",
                    add_new_entry: "pridať novú položku",
                    add_new_prior_entry: "pridať novú predošlú položku",
                    export_entries_to: "Export položiek do .XLSX",
                    import_entries_from: "Import položiek z .XLSX",
                    entries: "Položky",
                    of_which_it_is: "z ktorých je",
                    edit_adjustment_entry: "Aktualizácia položky úpravy",
                    form: "Formulár",
                    ruleset: "Súbor pravidiel",
                    trial_balance: "Hlavná kniha",
                    journal_entry: "Denník",
                    tax_type: "Typ dane",
                    book_value: "Účtovná hodnota",
                    tax_value: "Daňová hodnota",
                    rate: "Sadzba",
                    tax: "Vypočítaná daň",
                    value_adjustment: "Úprava hodnoty",
                    tax_recognised: "Daňovo uznané",
                    deferred_tax_prior_entries: "Predošlé položky",
                    deferred_tax_current_entries: "Aktuálne položky",
                    deferred_tax_result: "Výsledok",
                    accounting_result: "Výsledok účtovníctva",
                    journal_adjustments: "Úpravy denníka",
                    manual_adjustments: "Manuálne úpravy",
                    taxable_result_adjustments: "Úpravy zdaniteľného výsledku",
                    fixed_assets: "Dlhodobý majetok",
                    provisions: "Rezervy",
                    valuation_allowances: "Opravné položky",
                    cash_basis: "Uznateľné po zaplatení",
                    helpful: "Výpočtové úpravy",
                    required_fields: "Povinné polia",
                    fill_account_numbers_with_zeros: "Vyplň čísla účtov nulami",
                    adjustment_type: "Typ úpravy",
                    adjustment_settings_change_type_description: "Týmto spôsobom zmeníte typ úpravy:",
                    deferred_tax_settings: "Nastavenia odloženej dane",
                    deferred_tax_rate_opening: "Sadzba odloženej dane pri otvorení",
                    deferred_tax_rate_closing: "Sadzba odloženej danie pri zatvorení",
                    manual_entry: "Manuálna položka",
                    total: "Celkom",
                    subtotal: "Medzisúčet",
                    assets: "Aktíva",
                    liabilities: "Pasíva",
                    equal_to: "sa rovná",
                    not_equal_to: "sa nerovná",
                    less_than: "menej než",
                    greater_than: "viac než",
                    less_than_or_equal_to: "menej alebo rovne než",
                    greater_than_or_equal_to: "viac alebo rovne než",
                    contains: "obsahuje",
                    not_contains: "neobsahuje",
                    begins_with: "začína s",
                    ends_with: "končí s",
                    not_begins_with: "nezačína s",
                    not_ends_with: "nekončí s",
                    first_import_journal:
                        "Najprv musíte importovať účtovné záznamy. Pokračujte na „importovať denník“ a skúste to znova.",
                    import_settings: "Nastavenia importu",
                    remove_white_space_1st_account: "Odstrániť medzery (1. účet)",
                    remove_white_space_2nd_account: "Odstrániť medzery (2. účet)",
                    zero_padding_1st_account: "Použitie nulovej predvoľby na vyrovnanie dĺžky čísla účtu (1. účet)",
                    zero_padding_2nd_account: "Použitie nulovej predvoľby na vyrovnanie dĺžky čísla účtu (2. účet)",
                    use_combination_of_1st_2nd_account:
                        "Použite kombináciu 1. a 2. účtu ako 1. účet, ak je jedinečná iba ich kombinácia.",
                    example_short: "pr.",
                    skip_import: "preskočiť import",
                    shows_only_first_100_from: "zobrazuje sa len prvých 100 položiek",
                    shows_only_first: "zobrazuje sa len prvých",
                    fixed_assets_depreciation: "Odpisovanie dlhodobého majetku",
                    fixed_asset_disposal_by_sale: "Dlhodobý majetok vyradený predajom",
                    fixed_asset_disposal_by_scrapping_liquidation: "Dlhodobý majetok vyradený fyzickou likvidáciou",
                    damaged_or_stolen_assets: "Majetok vyradený z dôvodu škody alebo ukradnutý majetok",
                    questionnaire: "Dotazník",
                    paste_from_clipboard: "Prilepiť zo schránky",
                    select_from_attachments: "Vyberte z príloh",
                    search: "Vyhľadať",
                    select_all: "Vybrať všetky",
                    yes: "Áno",
                    no: "Nie",
                    fixed_intangible_assets: "Dlhodobý nehmotný majetok",
                    low_value_fixed_tangible_assets: "Dlhodobý drobný hmotný majetok",
                    fixed_tangible_assets: "Dlhodobý hmotný majetok bez drobného majetku",
                    total_assets: "Majetok spolu",
                    accounting_depreciation: "Účtovné odpisy",
                    tax_depreciation: "Daňové odpisy",
                    interrupted_depreciation_of_tangible_assets: "Prerušené odpisy hmotného majetku",
                    unused_part_of_fixed_asset_depreciation_in_the_year_of_capitalisation:
                        "Nezaúčtovaná časť odpisov dlhodobého majetku v roku obstarania",
                    difference_between_tax_and_accounting_depreciation: "Rozdiel medzi daňovými a účtovnými odpismi",
                    accounting_net_book_value: "Účtovná zostatková cena",
                    tax_net_book_value: "Daňová zostatková cena",
                    revenue_from_sale: "Príjem z predaja",
                    difference_between_tax_and_accounting_value: "Rozdiel medzi účtovnou a daňovou zostatkovou cenou",
                    tax_net_book_value_of_individual_assets_up_to_compensation_received:
                        "Daňová zostatková cena majetku do výšky prijatých náhrad",
                    tax_net_book_value_of_individual_assets_exceeding_compensation_received:
                        "Daňová zostatková cena majetku presahujúca prijaté náhrady",
                    compensation_received_for_individual_damaged_assets_exceeding_tax_book_value:
                        "Prijaté náhrady za majetok vyradený z dôvodu śkody presahujúce daňovú zostatkovú cenu",
                    total_tax_net_book_value: "Celková daňová zostatková cena",
                    total_compensation_received: "Celková suma prijatých náhrad",
                    sum_earnings: "Celkové zisky",
                    records_type: "Typ záznamov",
                    wealth_number: "Číslo majetku",
                    realistic_value: "Realistická hodnota",
                    high_cost: "Vysoké náklady",
                    opening_date: "Dátum otvorenia",
                    closing_date: "Dátum uzavretia",
                    number: "Číslo",
                    income: "Príjem",
                    percent_cost: "Percentuálny náklad",
                    calculations: "Výpočty",
                    cost: "Náklad",
                    sum_sponsors: "Suma sponzorov",
                    cislo_suvahoveho_uctu_opravnej_polozky_spolocnosti: "Číslo rozvahy opravy položky spoločnosti",
                    druh_opravnej_polozky: "Druh opravy položky",
                    zaciatocny_stav_opravnej_polozky: "Počiatočný stav opravy položky",
                    tvorba_opravnej_polozky: "Vytvorenie opravy položky",
                    dlznik_zakaznik: "Dlžník zákazník",
                    cost_high: "Vysoký náklad",
                    reward: "Odmena",
                    info: "Info",
                    reason: "Dôvod",
                    damages: "Škody",
                    refund_amount: "Suma refundácie",
                    replacements: "Náhrady",
                    details: "Detaily",
                    limits: "Limity",
                    treatments: "Ošetrenia",
                    sum: "Suma",
                    bill: "Faktúra",
                    dph: "DPH",
                    phone_number: "Telefónne číslo",
                    operations: "Operácie",
                    analysis: "Analýza",
                    hours: "Hodiny",
                    student: "Študent",
                    copy_contract: "Kopírovať kontrakt",
                    return: "Návrat",
                    type_of_transaction: "Typ transakcie",
                    date: "Dátum",
                    funding_information: "Informácie o financovaní",
                    asset_number: "Číslo aktíva",
                    asset_description: "Popis aktíva",
                    fair_value_of_the_asset: "Spravodlivá hodnota aktíva",
                    costs: "Náklady",
                    asset_name: "Názov aktíva",
                    percent_of_deduction: "Percento odpočtu",
                    internal_document_number: "Interné číslo dokumentu",
                    document_date: "Dátum dokumentu",
                    narrative_of_transaction: "Popis transakcie",
                    respective_pl_account: "Príslušný účet zisku a strát",
                    identification_of_costs: "Identifikácia nákladov",
                    amount_of_the_respective_payment: "Čiastka príslušnej platby",
                    amount_of_related_expenses: "Čiastka súvisiacich nákladov",
                    questionId: "ID otázok",
                    enter_value: "Zadajte hodnotu",
                    logs: "Záznamy",
                    no_content: "Žiadny obsah pre",
                    add_attachment: "Pridať prílohu zo zoznamu / Nahrať novú prílohu",
                    unselect_attachment: "Táto akcia odstráni prílohu zo zoznamu vybraných príloh pre danú otázku",
                    date_format: "dd/mm/rrrr",
                    pick_a_date: "Vyberte dátum",
                    PwC_prepared_tax_calculation_and_tax_return_for_preceding_tax_period:
                        "PwC pripravovalo kalkuláciu a daňové priznanie za predchádzajúce zdaňovacie obdobie",
                    recognised_on_the_companys_balance_sheet: "Zaradené do hmotného majetku Spoločnosti",
                    leased_under_operative_leasing_agreement: "Prenajímané na operatívny lízing",
                    no_comments_yet: "Pre danú odpoveď zatiaľ neboli pridané komentáre",
                    no_attachments_yet: "Pre danú odpoveď zatiaľ neboli priradené prílohy",
                    add_new_comment: "Pridajte nový komentár",
                    write_comment: "Napíšte komentár",
                    your_comment: "Váš komentár",
                    add_comment: "Pridať komentár",
                    assign_question: "Priradiť túto otázku:",
                    clear_date: "Vymazať dátum",
                    filter_by_keyword: "Filtrovať podľa kľúčového slova",
                    flagged: "Označené",
                    clear_filters: "Vymazať filtre",
                    close_filters: "Zatvoriť filtre",
                    filter_error: "Neboli nájdené žiadne výsledky pre dané kritéria filtra",
                    submit: "Odovzdať",
                    important: "Dôležité",
                    allow_sorting: "Povoliť zoradenie",
                    sorting_allowed: "Zoradenie povolené",
                    limit_of_entries_per_page: "Limit výsledkov na stránku",
                    comment_official_generic: "Oficiálny komentár je všeobecný",
                    deferred_tax_classification: "Klasifikácia odloženej dane",
                    parent: "Rodič",
                    category_condition: "Podmienka kategórie",
                    id: "ID",
                    adjustment_name_en: "Názov úpravy en",
                    name_sk: "Nazov SK",
                    category_group: "Skupina kategórií",
                    default_generic_comment_en: "Predvolený všeobecný komentár EN",
                    default_generic_comment_sk: "Predvolený všeobecný komentár SK",
                    priority: "Priorita",
                    for_release: "Na vydanie",
                    for_creation: "Pre Stvorenie",
                    for_adjustment_entry_tax_type: "Pri opravnom vstupe typ dane",
                    increase_tax_base: "Zvýšiť základ dane",
                    calculation_step: "Krok výpočtu",
                    default_deferred_tax_classification: "Štandardná klasifikácia odloženej dane",
                    language: "Jazyk",
                    ruleset_category_id: "ID kategórií sady pravidiel",
                    row_type: "Typ riadku",
                    html_type: "typ HTML",
                    data_type: "Dátový typ",
                    input_type: "Typ vstupu",
                    calculate_with: "Počítajte s",
                    print_with: "Tlačiť pomocou",
                    show: "Zobraziť",
                    help_text_en: "Pomocný text EN",
                    help_text_sk: "Pomocný text SK",
                    description_en: "Popis EN",
                    description_sk: "Popis SK",
                    question_views: "Názory na otázky",
                    question_group: "Skupina otázok",
                    clear_selected: "Vymazať vybraté",
                    attachments_upload_allowed: "Nahrávanie príloh je povolenéd",
                    please_separate: "Možnosti oddeľte čiarkou bez medzery.",
                    file_templates: "Šablóny súborov",
                    add_new_template: "Pridať novú šablónu",
                    list_of_templates: "Zoznam šablón",
                    choices: "Voľby",
                    attachment_allowed: "Príloha je povolená",
                    views: "Zobrazenia",
                    equivalent_id: "Ekvivalentné ID",
                    version: "Verzia",
                    select_the_relevant: "Výberom príslušného dotazníka zobrazíte jeho otázky.",
                    tax_form: "Daňový formulár",
                    adjustment_category_for: "Kategória úprav pre sady pravidiel",
                    selected_ruleset: "Vybraná sada pravidiel",
                    category_name_sk: "Názov kategórie SK",
                    category_name_en: "Názov kategórie EN",
                    country_key: "Kľúč krajiny",
                    clear_valid_to: "jasné platné do dátumu a času",
                    valid_from: "Platné od",
                    valid_to: "Platné do",
                    adjustment_entry_tax_type: "Opravný vstup druh dane",
                    adjustment_entry_type: "Typ položky nastavenia",
                    valid: "Platné",
                    ruleset_form: "Formulár súboru pravidiel",
                    tag: "Tag",
                    type: "Typ",
                    type_sk: "Typ SK",
                    tag_id: "ID značky",
                    creation_category_id: "ID kategórie tvorby",
                    release_category_id: "ID kategórie vydania",
                    timing: "Načasovanie",
                    select_form: "Vyberte formulár",
                    select_questionnaire: "Vyberte dotazník",
                    select_ruleset: "Vyberte sadu pravidiel",
                    category_id: "ID kategórie",
                    ruleset_id: "ID sady pravidiel",
                    copy_selected_form: "Skopírujte vybraný formulár",
                    confirm_and_copy: "Potvrdiť a skopírovať",
                    new_name: "Nové meno",
                    new_name_sk: "Nové meno SK",
                    new_xsd_schema_link: "Nový odkaz na schému XSD",
                    new_xsd_schema_path: "New XSD Schema Path",
                    download_json: "Stiahnuť _all.json",
                    download_attachments: "Stiahnuť prílohy",
                    export_questionnaire: "Export",
                    fixtures: "Fixtures",
                    add_and_update_initial: "Pridať a aktualizovať počiatočné sady pravidiel",
                    go: "Choď",
                    add_and_update_initial_ruleset_forms: "Pridať a aktualizovať počiatočné formuláre sady pravidiel",
                    add_and_update_initial_form_rows: "Pridať a aktualizovať úvodné riadky formulára",
                    add_and_update_questions_in_questionnaires: "Pridajte a aktualizujte otázky v dotazníkoch",
                    add_and_update_category_groups: "Pridať a aktualizovať skupiny kategórií",
                    add_and_update_category: "Pridať a aktualizovať kategóriu",
                    add_and_update_ruleset_category_pairs: "Pridať a aktualizovať páry kategórií sady pravidiel",
                    add_and_update_treatment_types: "Pridať a aktualizovať typy liečby",
                    add_and_update_tags_for_rulesets: "Pridať a aktualizovať značky pre sady pravidiel",
                    add_and_update_tag: "Pridať a aktualizovať značku",
                    add_and_update_adjustment_default: "Pridať a aktualizovať predvolené nastavenie",
                    add_and_update_adjustment_default_ruleset: "Pridať a aktualizovať predvolenú sadu pravidiel úpravy",
                    admin_dashboard: "Panel správcu",
                    add_and_update_initial_data_as_ruleset:
                        "Pridajte a aktualizujte počiatočné údaje ako sadu pravidiel, formuláre, kategórie, predvolené úpravy,...",
                    here_you_can_upload_new_attachment:
                        "Tu môžete nahrať novú prílohu. Maximálna veľkosť súboru je 1 GB.",
                    refresh: "Obnoviť",
                    some_filters_can_be_deleted: "niektoré filtre je možné vymazať,",
                    some_adjustment_entries_can: "niektoré položky úprav možno pridať alebo odstrániť,",
                    some_subadjustment_can_be_added: "niektoré podúpravy možno pridať alebo odstrániť",
                    if_you_change_adjustment_type: "Ak zmeníte typ úpravy:",
                    are_you_sure_you_want_to_delete: "Naozaj chcete odstrániť tento filter?",
                    account_2nd: "Účet 2",
                    description_2nd: "Popis (2.)",
                    entry_date: "Dátum vloženia",
                    document_no_external: "Číslo dokumentu - externé",
                    document_no_clearing: "Číslo dokumentu - Clearing ",
                    order_no: "Číslo objednávky",
                    offsetting_account_number: "Kompenzačné číslo účtu",
                    offsetting_account_number_2: "Kompenzačné číslo účtu 2",
                    partner_name: "Meno partnera",
                    period: "Obdobie",
                    posting_key: "Kľúč pre zaúčtovanie",
                    posting_type: "Typ účtovania",
                    profit_centre: "Ziskové stredisko",
                    cost_centre: "Nákladové stredisko",
                    project: "Projekt",
                    tax_code: "Kód dane",
                    tax_code_2: "Kód dane 2",
                    tax_date: "Dátum zdanenia",
                    field: "Pole",
                    operator: "Operátor",
                    select_tag: "Vyberte značku",
                    please_enter_a_name: "Zadajte meno",
                    current_prior: "aktuálny - predchádzajúci",
                    current_prior_with_current: "aktuálne - predchádzajúce s aktuálnou sadzbou",
                    please_enter_a_deferred_tax_rate: "Zadajte otvorenie sadzby odloženej dane",
                    please_enter_a_deferred_tax_rate_closing: "Zadajte uzávierku odloženej dane",
                    enter_the_required_information: "Zadajte potrebné informácie",
                    result_formula: "Vzorec výsledku:",
                    all_the_inputs_below_are: "Všetky nižšie uvedené vstupy sa pri zmene automaticky uložia",
                    select_relevant_form_for: "Vyberte príslušný formulár na export",
                    select_relevant_questionnaire: "Vyberte príslušný dotazník",
                    selectrelevant_questionnaire_view: "Vyberte relevantné zobrazenie dotazníka",
                    questionnaire_view: "Zobrazenie dotazníka",
                    download: "Stiahnuť",
                    edit_tax_form: "Upraviť daňový formulár",
                    select_field: "Vyberte položku Pole",
                    is: "je",
                    select_operator: "Vyberte matematický operátor",
                    add_filter: "Pridajte filter",
                    journal_column_mapping: "Mapovanie stĺpcov denníka",
                    import_trial_balance: "Import hlavnej knihy",
                    list_of_questions_is_empty: "Zoznam otázok je prázdny",
                    all: "Všetky",
                    for_review: "Na kontrolu",
                    for_second_review: "Na druhú kontrolu",
                    in_progress: "Prebieha",
                    trial_balance_was_downloaded: "Skúšobná bilancia bola stiahnutá",
                    tag_name: "Název značky",
                    order: "Order",
                    fullfilled: "Splněno",
                    no_client_was_found: "Nebol nájdený žiadny klient",
                    add_member: "Pridať člena",
                    access_to_the_requested: "403 - Prístup k požadovanému zdroju je zakázaný!",
                    add_new_entity: "Pridať novú entitu",
                    signing_you_in: "Prebieha prihlasovanie...",
                    signing_you_out: "Prebieha odhlasovanie...",
                    login: "Prihláste sa",
                    you_can_wait_here_until: "Tu môžete počkať, kým bude opäť online.",
                    if_you_refresh: "Ak obnovíte, pravdepodobne stratíte svoj pokrok.",
                    are_you_sure_you_want_to_delete: "Naozaj chcete odstrániť túto položku? Táto akcia je trvalá.",
                    delete: "Odstrániť",
                    sorry_something_is_wrong: "Prepáčte, niečo nie je v poriadku",
                    its_nice_to_have_you_back: "Je pekné mať ťa späť,",
                    by_clicking_the_button_below:
                        "Kliknutím na tlačidlo nižšie budete presmerovaní na prihlásenie pod svojím",
                    log_in: "Prihláste sa",
                },
                base: {
                    welcome: "Ahoj Svet",
                    next: "Ďalej",
                    back: "Späť",
                },
                header: {
                    CalculationInfoLabel: "Nastavenia",
                    SummaryLabel: "Zhrnutie",
                    EntityInfoLabel: "Poznámky o entite",
                    QuestionnaireLabel: "Dotazník",
                    ImportLabel: "Import",
                    TrialBalanceLabel: "Hlavná kniha",
                    TaxFlowsLabel: "Daňové úpravy",
                    TaxBaseLabel: "Základ dane",
                    ReviewLabel: "Prehľad",
                    AttachmentsLabel: "Prílohy",
                    DeferredTaxLabel: "Odložená daň",
                    ExportsLabel: "Exporty",
                    CalculationAttachmentsLabel: "Zoznam príloh kalkulácie",
                    FilterLabel: "Filter",
                    AdjustmentLabel: "Úprava",
                    AdjustmentEntriesLabel: "Položky úpravy",
                    name: "Názov",
                    category: "Kategória",
                    tax_classification: "Daňová klasifikácia",
                    comment: "Komentár",
                    filters: "Filtre",
                    amount: "Suma",
                    coefficient: "Koeficient",
                    review_status: "Review status",
                },
                client_overview: {
                    LayoutTitle: "Moji klienti",
                    ArchivedLabel: "Archivované",
                    new_calculation: "nová kalkulácia",
                    entity_count: "entít",
                    entity_count_2_4: "entity",
                    entity_count_1: "entita",
                    new_client: "nový klient",
                },
                client_create: {
                    CreateLabel: "Vytvorte nového klienta",
                    Name: "Meno klienta",
                },
                footer: {
                    copyrightText:
                        "Všetky práva vyhradené. PwC označuje slovenskú členskú firmu a niekedy môže označovať aj sieť PwC. <br />Každá členská firma je samostatnou právnickou osobou. Bližšie informácie nájdete na stránke <a class='ap-link' href='https://www.pwc.com/structure'>www.pwc.com/structure</a>.<br /><br />",
                    TOSLabel: "Podmienky používania a právne upozornenia",
                    TOSLink: todo,
                    PrivacyLabel: "Ochrana osobných údajov a súbory cookie",
                    PrivacyLink: todo,
                    DataControllerLabel: "Prevádzkovateľ údajov",
                    DataControllerLink: todo,
                },
                calculation_base: {
                    tag: "Tag",
                    account_number: "Číslo účtu",
                    account: "Účet",
                    account_2nd: "Číslo účtu (2.)",
                    account_name: "Názov účtu",
                    opening_balance: "Počiatočný stav",
                    amount: "Suma",
                    net_movement: "Net Movement",
                    debit: "Má dať",
                    credit: "Dal",
                    closing_balance: "Konečný stav",
                    document_no: "Číslo dokumentu",
                    document_no_external: "Číslo dokumentu - externé",
                    document_no_clearing: "Číslo dokumentu - Clearing ",
                    document_date: "Dátum dokumentu",
                    posting_date: "Dátum zaúčtovania",
                    description: "Popis",
                    amount_in_doc_curr: "Suma v mene na dok.",
                    cost_centre: "Nákladové stredisko",
                    credit_amount: "Suma Dal",
                    debit_amount: "Suma Má dať",
                    description_2nd: "Popis (2.)",
                    document_currency: "Mena na doklade",
                    document_type: "Typ dokumentu",
                    entry_date: "Dátum vloženia",
                    order_no: "Číslo objednávky",
                    offsetting_acct_no: "Kompenzačné číslo účtu",
                    offsetting_acct_no_2nd: "Kompenzačné číslo účtu (2.)",
                    partner_name: "Meno partnera",
                    period: "Obdobie",
                    posting_key: "Kľúč pre zaúčtovanie",
                    posting_type: "Typ účtovania",
                    profit_centre: "Ziskové stredisko",
                    project: "Projekt",
                    tax_code: "Kód dane",
                    tax_code_2: "Kód dane 2",
                    tax_date: "Dátum zdanenia",
                    choose_type: "Vyberte typ načítania údajov",
                    replace: "Nahradiť",
                    old_entries: "- staré záznamy budú odstránené a položky zo súboru budú pridané",
                    increment: "Pridať",
                    all_entries: "- všetky položky zo súboru budú pridané",
                    edit_data: "Úprava dát",
                    official_comment: "Oficiálny komentár",
                    as: "as",
                    debit_credit_net_movement: "Má dať & Dal alebo Net movement",
                },
                calculation_create: {
                    label: "Nová kalkulácia",
                    name: "Názov kalkulácie",
                    type: "Typ kalkulácie",
                    language: "Jazyk kalkulácie",
                    previousCalculation: "Predchádzajúca kalkulácia",
                    selectDesiredLanguage: "Vyberte požadovaný jazyk",
                    selectCalculationType: "Vyberte typ kalkulácie",
                    selectCalculation: "Vyberte kalkuláciu",
                    internalDescription: "Interný popis",
                    reviewers_1: "Prvý kontrolór",
                    reviewers_2: "Druhý kontrolór",
                    saveAndClose: "Uložiť & Zatvoriť",
                    createAndNext: "Vytvoriť & Ďalej",
                    cancel: "Zrušiť",
                },
                calculation_import: {
                    "Data validation": "Validácia dát",
                    title: "Import",
                    pleaseMapColumns: "Namapujte stĺpce súboru",
                    account: "Účet",
                    description: "Popis",
                    amount: "Suma",
                    debit: "Debit",
                    credit: "Credit",
                    continue: "Pokračovať",
                    total_movement: "Celkový pohyb:",
                    import_is_running: "Import je spustený",
                    import_is_loaded: "Import je načítaný",
                    select_sheet: "Zvoľte zošit",
                },
                tax_flows: {
                    "accounting-result_label": "Účtovný výsledok",
                    journal_label: "Úpravy denníka",
                    manual_label: "Manuálne úpravy",
                    taxable_result_label: "Úpravy zdaniteľného výsledku",
                    fixed_assets_label: "Dlhodobý majetok",
                    provisions_label: "Rezervy",
                    valuation_allowances_label: "Opravné položky",
                    cash_basis_label: "Uznateľné po zaplatení",
                    helpful_label: "Výpočtové úpravy",
                    name: "Názov",
                    category: "Kategória",
                    tax_classification: "Daňová klasifikácia",
                    comment: "Komentár",
                    filters: "Filtre",
                    amount: "Suma",
                    coefficient: "Koeficient",
                    actions: "Akcie",
                    accounting_result: "Výsledok účtovníctva",
                    journal_adjustments: "Úpravy denníka",
                    manual_adjustments: "Manuálne úpravy",
                    taxable_result_adjustments: "Úpravy zdaniteľného výsledku",
                    fixed_assets: "Dlhodobý majetok",
                    provisions: "Rezervy",
                    valuation_allowances: "Opravné položky",
                    cash_basis: "Uznateľné po zaplatení",
                    helpful: "Výpočtové úpravy",
                },
                form: {
                    typeHere: "Píšte sem...",
                    typeEmail: "Sem zadajte email...",
                    periodRange: "Rozsah obdobia",
                    selectPeriodDateRange: "Vyberte obdobie",
                },
                settings: {
                    ClientSpecificLabel: "Nastavenia klienta",
                    EntitySpecificLabel: "Nastavenia entity",
                    users: "Užívatelia",
                    name: "Názov",
                    email_adress: "Emailová adresa",
                    entities: "Entity",
                    add_user: "Pridať užívateľa",
                    cancel: "Zrušiť",
                    enter_valid_email: "Prosím zadajte platnú emailovú adresu",
                    yes_delete: "Áno, vymazať",
                    are_you_sure: "Naozaj chcete odstrániť?",
                },
                entity_info: {
                    label: "Entity poznámky",
                    name: "Názov",
                    description: "Popis",
                    actions: "Akcie",
                    add_new_info: "pridať novú poznámku",
                },
                Import_trial_balance_adjustmentForm: {
                    add_new_account: "pridať nový účet",
                    account_number: "Číslo účtu",
                    account_name: "Názov účtu",
                    description: "Popis",
                    amount: "Suma",
                    add_new: "pridať",
                },
                adjustment_form: {
                    add_adjustment: "pridať úpravu",
                },
                calculation_list: {
                    name: "Názov",
                    preiod_start: "Začiatok obdobia",
                    period_end: "Koniec obdobia",
                    status: "Status",
                    description: "Popis",
                },
                import: {
                    import_trial_balance: "Import hlavnej knihy",
                    trial_balance_column_mapping: "Mapovanie stĺpcov hlavnej knihy",
                    import_journal: "Import denníka",
                    journal_column_mapping: "Mapovanie stĺpcov denníka",
                    trial_balance_adjustments: "Úpravy hlavnej knihy",
                    data_check: "Kontrola údajov",
                    import_steps: "Import",
                    upload_instruction:
                        "Môžete nahrať súbory XLS alebo XLSX alebo CSV. Maximálna veľkosť súboru je 1 GB.",
                },
                tax_base: {
                    calculation_of_tax_base: "Výpočet základu dane",
                    increasing_items: "Navyšujúce položky",
                    decreasing_items: "Znižujúce položky",
                    subtotal: "Medzisúčet",
                    tax_base: "Základ dane",
                    "Total amount": "Celkom",
                    "Items increasing & decreasing tax base": "Položky navyšujúce & znížujúce základ dane",
                    "Earnings before tax": "Zisk pred zdanením",
                    "Other tax base adjusting items": "Ostatné položky upravujúce základ dane",
                },
                export_base: {
                    export_types: "Typy exportu",
                    tax_calculation: "Tax calculation.xlsx",
                    tax_form_pdf: "Tax form.pdf",
                    tax_form_xml: "Tax form.xml",
                    memory_reports: "Memory reports.xls",
                    tdt_figures: "TDT figures.n/a",
                },
            },
        },
        fallbackLng: "en",
        debug: process.env.REACT_APP_I18N_DEBUG,

        // have a common namespace used around the full app
        ns: ["base"],
        defaultNS: "base",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
