import React, {useCallback} from "react";
import {Button, Input, InputNumber, Panel, Select, Loading, Badge, Pagination} from "@appkit4/react-components";
import {Table, Column} from "@appkit4/react-components/table";
import {Filter} from "../../../types/Filter";
import {useGlobalStore} from "../../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {Number} from "../../../misc/AdjustmentColumns";
import Centered from "../../../misc/Centered";
import {Tag} from "../../../types/Tag";
import {useTr} from "../../../utils/trUtil";
import {randomstring} from "../../../utils/randomstring";
import {zeroToString} from "../../../utils/zeroToString";
import {stripHtml} from "../../../utils/stripHtml";
import PagePanel from "../../../misc/PagePanel";
import Layout from "../../Layout/Layout";
import CanShowCalculationMiddleware from "../../../misc/CanShowCalculationMiddleware";
import {HeaderType} from "../../../types/HeaderType";
import {useNavigate, useParams} from "react-router-dom";
import {onlyUnique} from "../../../utils/onlyUnique";
import BackButton from "../../../misc/BackButton";
import {getCalculationBaseUrl} from "../../../utils/userUtil";
import {JournalEntry} from "../../../types/JournalEntry";
import {numberToLocaleString} from "../../../utils/numberToLocaleString";
import "./FilterForm.scss";
import AdjustmentCreateModal from "./AdjustmentCreateModal";

declare type PropTypes = {
    temporaryFilter?: boolean;
    tempAccountNumber?: string;
};

declare type FilterObject = {
    hash?: string;
    index?: number;
    field?: string;
    operator?: string;
    values?: string[];
};

export const FilterFormContent: React.FC<PropTypes> = (props: PropTypes) => {
    const trg = useTr("global");
    const trh = useTr("header");
    const tr = useTr("adjustment_form");

    const [id, setId] = React.useState<number>();
    const [adjustment_id, setAdjustmentId] = React.useState<number>();

    const [toCreateNewForm, setToCreateNewForm] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const calculation = useGlobalStore((s) => s.currentCalculation);
    const entity = useGlobalStore((s) => s.currentCalculationEntity);
    const loadAdjustments = useGlobalStore((s) => s.loadAdjustments);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const navigate = useNavigate();
    const temporaryFilter = props.temporaryFilter;
    const tempAccountNumber = props.tempAccountNumber;
    const params = useParams();

    const [name, setName] = React.useState<string>("");
    const [description, setDescription] = React.useState<string>("");
    const [criteria, setCriteria] = React.useState<FilterObject[]>([]);
    const [limit, setLimit] = React.useState<any>(100);
    const [offset, setOffset] = React.useState<number>(0);
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout | null>(null);

    const [filter, setFilter] = React.useState<Filter>({} as Filter);
    const [filterIsLoaded, setFilterIsLoaded] = React.useState<boolean>(true);

    const [data, setData] = React.useState<any>();
    const [entries, setEntries] = React.useState<JournalEntry[]>([]);
    const [entriesNeedToUpdate, setEntriesNeedToUpdate] = React.useState<boolean>(false);
    const [entriesAreUpdated, setEntriesAreUpdated] = React.useState<boolean>(false);

    const [tags, setTags] = React.useState<any[]>([]);
    const [tagsAreLoaded, setTagsAreLoaded] = React.useState<boolean>(false);
    const [savingIsRunning, setSavingIsRunning] = React.useState<boolean>(false);

    const [nameError, setNameError] = React.useState<string>("");
    const [criteriaError, setCriteriaError] = React.useState<string>("");

    const [createAdjustmentModalVisible, setCreateAdjustmentModalVisible] = React.useState<boolean>(false);

    const filterIsValid = useCallback(() => {
        let isValid = true;
        if (!temporaryFilter) {
            if (name === "") {
                setNameError("Please enter filter name.");
                isValid = false;
            }

            if (criteria.length === 1 && criteria.some((item) => item.field === "")) {
                setCriteriaError("Please set at least one criterion.");
                isValid = false;
            }
        }
        return isValid;
    }, [criteria, name, setCriteriaError, setNameError, temporaryFilter]);

    React.useEffect(() => {
        if (params.adjustment_id) setAdjustmentId(parseInt(params.adjustment_id));
        if (params.id && params.id === "new") {
            setToCreateNewForm(true);
            setId(undefined);
        } else if (params.id) {
            setId(parseInt(params.id));
        }
    }, [params]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && id && !toCreateNewForm) {
            setFilterIsLoaded(false);
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/filter/${id}/`)
                .then((response) => response.json())
                .then((data: Filter | any) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setName(data.name);
                        setDescription(data.description);
                        setCriteria(data.criteria);
                        setFilter(data);
                        setEntriesNeedToUpdate(true);
                    }
                    setFilterIsLoaded(true);
                });
        } else if (temporaryFilter) {
            setFilter({} as Filter);
            setEntriesNeedToUpdate(true);
        }
    }, [
        entity,
        calculation,
        adjustment_id,
        id,
        setFilter,
        setCriteria,
        setFilterIsLoaded,
        setDescription,
        fetchApi,
        addErrorNotification,
        toCreateNewForm,
        temporaryFilter,
    ]);

    React.useEffect(() => {
        if (O.isSome(entity) && O.isSome(calculation) && tagsAreLoaded === false) {
            fetchApi(`/entity/${entity.value.hash}/calculation/${calculation.value.hash}/available_tags/`)
                .then((response) => response.json())
                .then((res) => {
                    typeof res.error !== "undefined" ? addErrorNotification(data) : setTags(res);
                    setTagsAreLoaded(true);
                });
        }
    }, [entity, calculation, tagsAreLoaded, addErrorNotification, data, fetchApi]);

    const prepareCriteriaForRequest = useCallback(() => {
        let c = [...criteria.filter((el) => el.field !== "")];
        for (let i = 0; i < c.length; i++) {
            c[i].values = c[i].values?.filter((v) => v !== "");
        }
        return c.filter((el) => el.values && el.values.length > 0);
    }, [criteria]);

    const updateEntries = useCallback(() => {
        setCriteriaError("");
        setEntriesAreUpdated(false);
        if (O.isSome(entity) && O.isSome(calculation) && filter && filterIsValid() && criteria.length > 0) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/filter_result_by_criteria/`,
                "post",
                {
                    criteria: prepareCriteriaForRequest(),
                    limit: limit,
                    offset: offset,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setData(data);
                        setEntries(
                            data.journal_entries.map((entry: JournalEntry) => {
                                return entry;
                            })
                        );
                    }
                    setEntriesAreUpdated(true);
                })
                .catch((error) => {
                    addErrorNotification(error);
                    setCriteriaError("Sorry, some error occurred while applying the criteria.");
                    setEntriesAreUpdated(true);
                });
        } else {
            setEntriesAreUpdated(true);
        }

        if (criteria && criteria.length === 0) setEntriesAreUpdated(true);
    }, [
        entity,
        calculation,
        addErrorNotification,
        criteria,
        fetchApi,
        filter,
        filterIsValid,
        prepareCriteriaForRequest,
        limit,
        offset,
    ]);

    React.useEffect(() => {
        if (entriesNeedToUpdate === true) {
            updateEntries();
            setEntriesNeedToUpdate(false);
        }
    }, [entriesNeedToUpdate, updateEntries]);

    const reloadAdjustmentResult = () => {
        if (filterIsValid() && O.isSome(entity) && O.isSome(calculation) && filter) {
            setSavingIsRunning(true);
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/adjustment/${adjustment_id}/result/`
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        loadAdjustments();
                        setEntriesNeedToUpdate(true);
                    }
                    setSavingIsRunning(false);
                });
        }
    };

    const saveFilter = () => {
        if (filterIsValid() && O.isSome(entity) && O.isSome(calculation) && filter) {
            setSavingIsRunning(true);
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/filter/${filter.id}/`,
                !temporaryFilter ? "patch" : "post",
                {
                    name: name,
                    description: description,
                    criteria: criteria
                        .filter((item) => item.field !== "")
                        .map((item) => ({...item, values: item.values?.filter((value) => value !== "")})),
                    adjustment_id: adjustment_id,
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        reloadAdjustmentResult();
                    }
                    setSavingIsRunning(false);
                });
        }
    };

    const deleteFilter = () => {
        if (filter && O.isSome(entity) && O.isSome(calculation)) {
            fetchApi(
                `/entity/${entity.value.hash}/calculation/${calculation.value.hash}/filter/${filter.id}/`,
                "delete"
            )
                .then((res) => res.json())
                .catch(() => {
                    loadAdjustments();
                    navigate(-1);
                });
        } else {
            navigate(-1);
        }
    };

    React.useEffect(() => {
        if (temporaryFilter && criteria.length === 0) {
            setCriteria([
                {
                    index: 0,
                    hash: randomstring(8),
                    field: "account",
                    operator: "=",
                    values: [tempAccountNumber || ""],
                },
            ]);
            setEntries([]);
        } else if (criteria.length === 0) {
            setCriteria([
                {
                    index: 0,
                    hash: randomstring(8),
                    field: "",
                    operator: "=",
                    values: [""],
                },
            ]);
            setEntries([]);
        } else if (!criteria.some((item, index) => item.field === "")) {
            setCriteria((s) => [
                ...s,
                {
                    index: criteria.length,
                    hash: undefined,
                    field: "",
                    operator: "=",
                    values: [""],
                },
            ]);
            setEntries([]);
        }
    }, [criteria, temporaryFilter, tempAccountNumber]);

    const updateCriteriaRow = (data: FilterObject, index: number) => {
        let new_criteria = criteria.map((criterion, i) => {
            let new_data = i === index ? data : criterion;
            if (!new_data.hash) {
                new_data.hash = randomstring(8);
            }
            return new_data;
        });
        setCriteria(new_criteria);
    };

    const deleteCriteriaRow = (hash: string) => {
        setCriteria((state) => {
            let new_criteria: FilterObject[] = [];
            state.forEach((c) => {
                if (c.hash === hash) return false;
                new_criteria.push(c);
                return true;
            });
            return new_criteria;
        });
    };

    const renderAssignAdjCell = (field) => {
        return field.adjustment_id ? (
            <>
                <Button
                    compact={true}
                    kind={"danger"}
                    onClick={() => {
                        let url =
                            getCalculationBaseUrl(entity, calculation) + "/tax-flows/adjustment/" + field.adjustment_id;
                        if (url) navigate(url);
                    }}
                >
                    {field.adjustment_name}
                    {adjustment_id && parseInt(field.adjustment_id) !== adjustment_id && (
                        <Badge className="p-0 px-1 ms-1" size="sm" value={"conflict"} type={"danger-outlined"}></Badge>
                    )}
                </Button>
            </>
        ) : (
            "-"
        );
    };

    const onClickDeleteHandler = () => {
        if (window.confirm(trg("are_you_sure_you_want_to_delete"))) deleteFilter();
    };

    return (
        <PagePanel
            title={
                <>
                    <small>{`${trh("FilterLabel")}:`}</small> {`${name ? name : ""}`}
                </>
            }
            className={temporaryFilter ? "temporary-filter adjustment-page" : "adjustment-page"}
            buttons={
                !temporaryFilter && (
                    <div className="d-flex">
                        <Button
                            compact
                            kind={"negative"}
                            className="me-2"
                            icon={"icon-delete-outline"}
                            onClick={onClickDeleteHandler}
                        >
                            {trg("delete_filter")}
                        </Button>
                        <BackButton />
                    </div>
                )
            }
        >
            {filterIsLoaded ? (
                <>
                    <div className="row">
                        <div className="col-md-12">
                            <Panel>
                                {!temporaryFilter && (
                                    <div className="row">
                                        <div className="col-md-4 mt-3">
                                            <Input
                                                type="text"
                                                title={trg("filter_name")}
                                                value={name}
                                                onChange={(v) => {
                                                    setName(stripHtml(v));
                                                    setNameError("");
                                                }}
                                                error={nameError !== ""}
                                                errorNode={
                                                    <div aria-live="polite" className="ap-field-email-validation-error">
                                                        {nameError}{" "}
                                                    </div>
                                                }
                                            />
                                        </div>
                                        <div className="col-md-8 mt-3">
                                            <Input
                                                type="text"
                                                title={trg("description")}
                                                value={description ? description : ""}
                                                onChange={(v: any) => setDescription(stripHtml(v))}
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md-12 pt-3">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">
                                                <h5>{trg("where")}</h5>
                                            </div>
                                        </div>
                                        {criteriaError && (
                                            <div className="ap-field-content">
                                                <div aria-live="polite" className="ap-field-email-validation-error">
                                                    {criteriaError}{" "}
                                                </div>
                                            </div>
                                        )}
                                        {criteria
                                            .filter((c) => c.hash !== undefined)
                                            .map((c: FilterObject, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="d-flex">
                                                            <div
                                                                className="mt-4"
                                                                style={{
                                                                    paddingRight: "10px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() => deleteCriteriaRow(c.hash ? c.hash : "")}
                                                            >
                                                                <span
                                                                    className="Appkit4-icon icon-delete-outline"
                                                                    title={trg("delete_filter_criterion")}
                                                                ></span>
                                                            </div>
                                                            <CriteriaRow
                                                                available_tags={tagsAreLoaded ? tags : []}
                                                                index={index}
                                                                key={c.hash}
                                                                data={c}
                                                                onChange={(data) => {
                                                                    updateCriteriaRow(data, index);
                                                                    setCriteriaError("");
                                                                }}
                                                            />
                                                        </div>
                                                        {criteria.length > index + 1 && (
                                                            <div style={{marginLeft: "40px"}}>
                                                                <span className="text-muted">{trg("and")}</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <InputNumber
                                        title={trg("limit_of_entries_per_page")}
                                        className="m-3"
                                        value={limit}
                                        onChange={(value) => {
                                            setLimit(value);
                                            if (timeoutId) {
                                                clearTimeout(timeoutId);
                                            }

                                            const newTimeoutId = setTimeout(() => {
                                                setEntriesNeedToUpdate(true);
                                            }, 1500);

                                            setTimeoutId(newTimeoutId);
                                        }}
                                    />
                                    <div className="d-flex">
                                        <Button
                                            kind="secondary"
                                            className="m-4 mx-5"
                                            compact
                                            onClick={() => setEntriesNeedToUpdate(true)}
                                            loading={!entriesAreUpdated}
                                        >
                                            {trg("apply_filter")}
                                        </Button>
                                    </div>
                                    <div className="flex-grow-1"></div>
                                    {!temporaryFilter ? (
                                        <div>
                                            <Button onClick={saveFilter} kind="primary" loading={savingIsRunning}>
                                                {trg("save_filter")}
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <AdjustmentCreateModal type="journal" filter={criteria} />
                                        </div>
                                    )}
                                </div>
                            </Panel>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 mt-3">
                            <Panel>
                                <h5>
                                    {trg("list_of_transactions_by_filter")}
                                    {entriesAreUpdated &&
                                        data &&
                                        typeof data.total_count_of_journal_entries !== "undefined" && (
                                            <>
                                                <small className="text-muted fw-normal">
                                                    &nbsp;&nbsp;(
                                                    {data.total_count_of_journal_entries > limit && offset === 0
                                                        ? `Shows only first ${limit} from ${data.total_count_of_journal_entries} entries`
                                                        : `Shows ${offset * limit} to ${Math.min(
                                                              offset * limit + limit,
                                                              data.total_count_of_journal_entries
                                                          )} from ${data.total_count_of_journal_entries} entries`}
                                                    )
                                                </small>
                                                <div className="float-end">
                                                    <small>{trg("total")}</small>:{" "}
                                                    {numberToLocaleString(data.total_amount)}
                                                </div>
                                            </>
                                        )}
                                </h5>
                                {entriesAreUpdated ? (
                                    entries.length > 0 ? (
                                        <div>
                                            <Table
                                                striped={true}
                                                condensed={true}
                                                hasTitle={true}
                                                originalData={zeroToString(entries)}
                                            >
                                                <Column sortKey="account__number">{trg("account_number")}</Column>
                                                <Column sortKey="posting_date">{trg("posting_date")}</Column>
                                                <Column sortKey="description">{trg("description")}</Column>
                                                <Column sortKey="document_no">{trg("document_no")}</Column>
                                                <Column
                                                    sortKey="amount"
                                                    renderCell={(row, field) => (
                                                        <Number field={field} row={row} amount={row.amount} />
                                                    )}
                                                >
                                                    {trg("amount")}
                                                </Column>
                                                <Column
                                                    sortKey="debit_amount"
                                                    renderCell={(row, field) => (
                                                        <Number field={field} row={row} amount={row.debit_amount} />
                                                    )}
                                                >
                                                    {trg("debit_amount")}
                                                </Column>
                                                <Column
                                                    sortKey="credit_amount"
                                                    renderCell={(row, field) => (
                                                        <Number field={field} row={row} amount={row.credit_amount} />
                                                    )}
                                                >
                                                    {trg("credit_amount")}
                                                </Column>
                                                <Column sortKey="adjustment_name" renderCell={renderAssignAdjCell}>
                                                    {trg("assigned_to")}
                                                </Column>
                                            </Table>
                                            <Pagination
                                                className="d-flex flex-grow-1 justify-content-center"
                                                current={offset + 1}
                                                total={
                                                    data.total_count_of_journal_entries > limit
                                                        ? Math.ceil(data.total_count_of_journal_entries / limit)
                                                        : 1
                                                }
                                                onPageChange={(page) => {
                                                    setOffset(page - 1);
                                                    if (timeoutId) {
                                                        clearTimeout(timeoutId);
                                                    }

                                                    const newTimeoutId = setTimeout(() => {
                                                        setEntriesNeedToUpdate(true);
                                                    }, 1000);

                                                    setTimeoutId(newTimeoutId);
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div className="text-muted">{trg("list_is_empty")}</div>
                                    )
                                ) : (
                                    <Centered>
                                        <Loading loadingType="linear" indeterminate={true} compact={false}></Loading>
                                    </Centered>
                                )}
                            </Panel>
                        </div>
                    </div>
                </>
            ) : (
                <Centered>
                    <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
                </Centered>
            )}
        </PagePanel>
    );
};

const FilterForm: React.FC<PropTypes> = (props: PropTypes) => {
    return (
        <CanShowCalculationMiddleware>
            <Layout headerType={HeaderType.Calculation}>
                <FilterFormContent temporaryFilter={false} tempAccountNumber=""></FilterFormContent>
            </Layout>
        </CanShowCalculationMiddleware>
    );
};

export default FilterForm;

declare type CriteriaRowPropTypes = {
    available_tags: Tag[];
    index: number;
    data: FilterObject;
    onChange: (object: FilterObject) => void;
};

export const CriteriaRow: React.FC<CriteriaRowPropTypes> = (props: CriteriaRowPropTypes) => {
    const [filterObject, setFilterObject] = React.useState<FilterObject>(props.data);
    const [field, setField] = React.useState(props.data.field ? props.data.field : "");
    const [fieldError, setFieldError] = React.useState(false);
    const [operator, setOperator] = React.useState(props.data.operator ? props.data.operator : "");
    const [values, setValues] = React.useState(
        props.data.values ? props.data.values.filter((v) => v !== "").concat([""]) : [""]
    );
    const [showTagSelect, setShowTagSelect] = React.useState<boolean>(false);
    const trg = useTr("global");
    const trcb = useTr("calculation_base");

    const FilterOperations = [
        {key: "=", value: "=", label: trg("equal_to")},
        {key: "!=", value: "!=", label: trg("not_equal_to")},
        {key: "<", value: "<", label: trg("less_than")},
        {key: ">", value: ">", label: trg("greater_than")},
        {key: "<=", value: "<=", label: trg("less_than_or_equal_to")},
        {key: ">=", value: ">=", label: trg("greater_than_or_equal_to")},
        {key: "%", value: "%", label: trg("contains")},
        {key: "~%", value: "~%", label: trg("not_contains")},
        {key: "s%", value: "s%", label: trg("begins_with")},
        {key: "%s", value: "%s", label: trg("ends_with")},
        {key: "!s%", value: "!s%", label: trg("not_begins_with")},
        {key: "!%s", value: "!%s", label: trg("not_ends_with")},
    ].map((e) => ({...e, label: `${e.value} ${e.label}`}));

    const FilterParameters = [
        {key: "tag", value: "tag", label: trg("tag")},
        {key: "account", value: "account", label: trg("account")},
        {key: "account_2nd", value: "account_2nd", label: trg("account_2nd")},
        {key: "account_name", value: "account_name", label: trg("account_name")},
        {key: "amount", value: "amount", label: trg("amount")},
        {key: "amount_in_doc_curr", value: "amount_in_doc_curr", label: trg("amount")},
        {key: "debit_amount", value: "debit_amount", label: trg("debit_amount")},
        {key: "credit_amount", value: "credit_amount", label: trg("credit_amount")},
        {key: "description", value: "description", label: trg("description")},
        {key: "description_2nd", value: "description_2nd", label: trg("description_2nd")},
        {key: "posting_date", value: "posting_date", label: trg("posting_date")},
        {key: "entry_date", value: "entry_date", label: trg("entry_date")},
        {key: "document_date", value: "document_date", label: trg("document_date")},
        {key: "document_no", value: "document_no", label: trg("document_no")},
        {key: "document_no_external", value: "document_no_external", label: trg("document_no_external")},
        {key: "document_no_clearing", value: "document_no_clearing", label: trg("document_no_clearing")},
        {key: "document_type", value: "document_type", label: trg("document_type")},
        {key: "document_currency", value: "document_currency", label: trg("document_currency")},
        {key: "order_no", value: "order_no", label: trg("order_no")},
        {key: "offsetting_acct_no", value: "offsetting_acct_no", label: trg("offsetting_account_number")},
        {key: "offsetting_acct_no_2nd", value: "offsetting_acct_no_2nd", label: trg("offsetting_account_number_2")},
        {key: "partner_name", value: "partner_name", label: trg("partner_name")},
        {key: "period", value: "period", label: trg("period")},
        {key: "posting_key", value: "posting_key", label: trg("posting_key")},
        {key: "posting_type", value: "posting_type", label: trg("posting_type")},
        {key: "profit_centre", value: "profit_centre", label: trg("profit_centre")},
        {key: "cost_centre", value: "cost_centre", label: trg("cost_centre")},
        {key: "project", value: "project", label: trg("project")},
        {key: "tax_code", value: "tax_code", label: trg("tax_code")},
        {key: "tax_code_2", value: "tax_code_2", label: trg("tax_code")},
        {key: "tax_date", value: "tax_date", label: trg("tax_date")},
    ].map((e) => ({...e, label: trcb(e.value)}));

    const onChangeValue = (value: string, index: number) => {
        let new_values = values.map((v, i) => {
            return i === index ? value : v;
        });
        setValues(new_values.filter(onlyUnique));
    };

    React.useEffect(() => {
        if (filterObject.field !== field || filterObject.operator !== operator || filterObject.values !== values) {
            setFilterObject((s) => {
                return {
                    index: s.index,
                    hash: s.hash,
                    field: field,
                    operator: operator,
                    values: values,
                };
            });
        }
    }, [field, operator, values, filterObject]);

    React.useEffect(() => {
        if (!field) {
            setFieldError(true);
            return;
        }
        props.onChange(filterObject);
    }, [filterObject, field, props]);

    React.useEffect(() => {
        if (field === "tag") {
            setOperator("=");
            setShowTagSelect(true);
        } else {
            setShowTagSelect(false);
        }
    }, [field, operator]);

    const getTags = () => {
        return props.available_tags.map((tag: Tag, index: number) => {
            return {
                label: tag.name,
                value: tag.name_en,
                descValue: index,
            };
        });
    };
    return (
        <>
            {props.available_tags.length > 0 ? (
                <div className="filter-form-component d-flex flex-wrap">
                    <Select
                        data={FilterParameters}
                        value={field}
                        defaultValue={field}
                        placeholder={trg("field")}
                        onSelect={(v: any) => {
                            setField(v);
                            setFieldError(false);
                        }}
                        searchable={true}
                        dropdownMatchWidth={false}
                        error={fieldError}
                        required
                    ></Select>
                    <Select
                        data={FilterOperations}
                        value={operator}
                        defaultValue={operator}
                        placeholder={trg("operator")}
                        onSelect={(v: any) => {
                            setOperator(v);
                        }}
                        dropdownMatchWidth={false}
                        required
                    ></Select>
                    {values.map((value, index) => {
                        return (
                            <div key={index} className="d-flex">
                                <div className="value-wrapper">
                                    {showTagSelect ? (
                                        <Select
                                            data={getTags()}
                                            value={value ? value : ""}
                                            dropdownMatchWidth={350}
                                            placeholder={trg("select_tag")}
                                            onSelect={(v: any) => {
                                                onChangeValue(v, index);
                                                setFieldError(false);
                                            }}
                                            required
                                            searchable
                                        ></Select>
                                    ) : (
                                        <div className="value-input-wrapper">
                                            <Input
                                                type="text"
                                                value={value ? value : ""}
                                                defaultValue={value ? value : ""}
                                                hideTitleOnInput={true}
                                                onChange={(v: string) => {
                                                    onChangeValue(stripHtml(v), index);
                                                    setFieldError(false);
                                                }}
                                                required
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="logic-operator-or" style={{padding: "2px"}}>
                                    <span className="text-muted">{trg("or")}</span>
                                </div>
                            </div>
                        );
                    })}
                    <div>
                        <Button
                            className="add-value-button"
                            kind="text"
                            icon="icon-circle-plus-outline"
                            onClick={(index) => setValues((s) => [...s, ""].filter(onlyUnique))}
                        ></Button>
                    </div>
                </div>
            ) : (
                <Loading loadingType="circular" indeterminate={true} compact={true}></Loading>
            )}
        </>
    );
};
