import React from "react";
import {useGlobalStore} from "../../../../stores/GlobalStore";
import {Select} from "@appkit4/react-components";
import {Questionnaire} from "../../../../types/Questionnaire";
import {useTr} from "../../../../utils/trUtil";

function useQuestionnaireSelection(disabled: boolean) {
    const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState<Questionnaire>();
    const [questionnaires, setQuestionnaires] = React.useState<Questionnaire[]>([]);
    const [areLoaded, setAreLoaded] = React.useState<boolean>(false);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const [toSelect, setToSelect] = React.useState<Questionnaire>();
    const trg = useTr("global");

    React.useEffect(() => {
        if (areLoaded === false)
            fetchApi(`/settings/_/fixtures/questionnaire/`)
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setQuestionnaires(data.results);
                        if (toSelect) {
                            setSelectedQuestionnaire(toSelect);
                            setToSelect(undefined);
                        }
                    }
                    setAreLoaded(true);
                })
                .catch((error) => {
                    addErrorNotification(error);
                    setAreLoaded(true);
                });
    }, [areLoaded, fetchApi, addErrorNotification, toSelect]);

    const setNewQuestionnaire = (newQuestionnaire: Questionnaire) => {
        setToSelect(newQuestionnaire);
        setAreLoaded(false);
    };

    const questionnaireSelector = (
        <Select
            data={questionnaires}
            labelKey="name"
            placeholder={trg("select_questionnaire")}
            valueKey="id"
            onSelect={(val) =>
                setSelectedQuestionnaire(val ? questionnaires.filter((f) => f.id === val)[0] : undefined)
            }
            itemTemplate={(val, item) => (
                <>
                    ({item.id}) - {item.name}
                </>
            )}
            disabled={selectedQuestionnaire && disabled}
        ></Select>
    );

    return {questionnaireSelector, selectedQuestionnaire, setNewQuestionnaire};
}

export default useQuestionnaireSelection;
