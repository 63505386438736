import React from "react";
import Layout from "../Layout/Layout";
import {Panel, Input, Select} from "@appkit4/react-components";
import {Modal} from "@appkit4/react-components/modal";
import {Badge} from "@appkit4/react-components/badge";
import {Button} from "@appkit4/react-components/button";
import {LayoutSettings} from "../Layout/LayoutSettings";
import {HeaderType} from "../../types/HeaderType";
import {useTr} from "../../utils/trUtil";
import "./EntitySpecific.scss";
import {useParams} from "react-router-dom";
import {useGlobalStore} from "../../stores/GlobalStore";
import * as O from "fp-ts/Option";
import {Notice} from "@appkit4/react-components/notification";
import {BackendUserInfo} from "../../types/UserInfo";
import {validateEmail} from "../../utils/validateEmail";
import {Entity} from "../../types/Entity";
import {country_keys, RoutingTable} from "../../Constants";
import {useNavigate} from "react-router-dom";
import {stripHtml} from "../../utils/stripHtml";
import {throwError} from "fp-ts/lib/ReaderEither";
import {Account} from "../../types/Account";

declare type PropTypes = {};

const EntitySpecific: React.FC<PropTypes> = (props: PropTypes) => {
    const tr = useTr("settings");
    const trb = useTr("base");
    const trg = useTr("global");

    const params = useParams();
    const [entity, setEntity] = React.useState<O.Option<Entity>>(O.none);
    const [members, setMembers] = React.useState<BackendUserInfo[]>([]);
    const [accounts, setAccounts] = React.useState<Account[]>([]);
    const [currentUser, setCurrentUser] = React.useState<O.Option<BackendUserInfo>>(O.none);
    const [currentUserIsOwner, setCurrentUserIsOwner] = React.useState<boolean>(false);
    const [dataAreLoaded, setDataAreLoaded] = React.useState<boolean>(false);
    const getEntityByHash = useGlobalStore((s) => s.getEntityByHash);
    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const getBackendUserInfo = useGlobalStore((s) => s.getBackendUserInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (typeof params.entityHash === "string") {
            setEntity(getEntityByHash(params.entityHash));
        }
    }, [params]);

    React.useEffect(() => {
        if (O.isSome(entity)) {
            fetchApi(`/entity/${entity.value.hash}/members/`)
                .then((response) => response.json())
                .then((data: BackendUserInfo[] | any) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        let backend_user = getBackendUserInfo();
                        if (!O.isNone(backend_user) && typeof entity.value.owner !== "undefined") {
                            setCurrentUserIsOwner(backend_user.value.id === entity.value.owner.id);
                        }
                        setCurrentUser(backend_user);
                        setMembers(data);
                    }
                });
        }
    }, [entity]);

    React.useEffect(() => {
        if (O.isSome(entity)) {
            fetchApi(`/entity/${entity.value.hash}/account/`)
                .then((response) => response.json())
                .then((data: Account[] | any) => {
                    if (typeof data.error !== "undefined") {
                        addErrorNotification(data);
                    } else {
                        setAccounts(data);
                    }
                });
        }
    }, [entity]);

    const renderMember = (member: BackendUserInfo) => {
        return (
            <tr key={member.id}>
                <td>{member.first_name ? `${member.first_name} ${member.last_name}` : member.email}</td>
                <td>{member.email}</td>
                <td className="text-end">
                    {O.isSome(currentUser) && currentUser.value.id === member.id && !currentUserIsOwner && ""}
                    {O.isSome(currentUser) && currentUser.value.id === member.id && currentUserIsOwner && "owner"}
                    {O.isSome(currentUser) && O.isSome(entity) && currentUser.value.id !== member.id && (
                        <DeleteMemberModal key={member.id} member={member} entity={entity.value} />
                    )}
                </td>
            </tr>
        );
    };

    const renderAccount = (account: Account) => {
        return (
            <tr key={account.id}>
                <td>{account.number}</td>
                <td>{account.name}</td>
                <td>{account.account_number_1 ? account.account_number_1 : "-"}</td>
                <td>{account.account_number_2 ? account.account_number_2 : "-"}</td>
                <td>
                    {renderActionCell(account)}‎ ‎ ‎ ‎{deleteAcountIcon(account)}
                </td>
            </tr>
        );
    };

    const deleteAccount = (account: Account) => {
        if (accounts && O.isSome(entity)) {
            fetchApi(`/entity/${entity.value.hash}/account/${account.id}/`, "delete")
                .then((res) => res.json())
                .catch(() => {
                    // navigate(-1);
                });
        } else {
            return false;
        }
    };

    const deleteAcountIcon = (account: Account) => {
        return (
            <span
                className="Appkit4-icon icon-delete-fill ap-font-24"
                style={{color: "#D04A02", cursor: "pointer"}}
                onClick={() => deleteAccount(account)}
            ></span>
        );
    };

    const renderActionCell = (account: Account) => {
        if (O.isSome(entity)) {
            return (
                <>
                    <EditAccountForm
                        key={`edit-${account.id}`}
                        account={account}
                        onSaveHandler={() => setDataAreLoaded(false)}
                        entity={entity.value}
                    />
                </>
            );
        }
    };

    if (O.isSome(entity))
        return (
            <Layout headerType={HeaderType.Settings}>
                <LayoutSettings>
                    <Panel className="settings-entity-specific-panel">
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <h2 className="d-inline">{tr("EntitySpecificLabel")}</h2>
                                <span>
                                    &nbsp;&nbsp;
                                    <span className="Appkit4-icon icon-arrow-right-small-outline"></span>
                                    &nbsp;&nbsp;{entity.value.name}
                                </span>
                            </div>
                            <div>
                                <Button
                                    kind="text"
                                    icon="icon-chevron-double-left-outline"
                                    onClick={() => navigate(RoutingTable.my_clients)}
                                >
                                    {trb("back")}
                                </Button>
                            </div>
                        </div>
                        <div style={{padding: "0 8px"}}>
                            <div className="clearfix">
                                <h3 className="float-start">{tr("users")}</h3>
                                <AddMemberModal entity={entity.value} />
                            </div>
                            <Panel>
                                <table className="table table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th style={{width: "30%"}}>{trg("name")}</th>
                                            <th style={{width: "30%"}}>{trg("email_adress")}</th>
                                            <th style={{width: "30%"}}>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {members.map((member) => {
                                            return renderMember(member);
                                        })}
                                    </tbody>
                                </table>
                            </Panel>
                        </div>
                        <div style={{padding: "0 8px"}}>
                            <div className="clearfix">
                                <h3 className="float-start">{trg("edit_data")}</h3>
                            </div>
                            <Panel>{O.isSome(entity) && <EntityFormModal entity={entity.value} />}</Panel>
                        </div>
                        <div style={{padding: "0 8px"}}>
                            <div className="clearfix">
                                <h3 className="float-start">{trg("accounts")}</h3>
                            </div>
                            <Panel>
                                <table className="table table-hover table-borderless">
                                    <thead>
                                        <tr>
                                            <th style={{width: "15%"}}>{trg("account_number")}</th>
                                            <th style={{width: "30%"}}>{trg("account_name")}</th>
                                            <th style={{width: "20%"}}>{trg("account_number_1")}</th>
                                            <th style={{width: "20%"}}>{trg("account_number_2")}</th>
                                            <th style={{width: "30%"}}>&nbsp;</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {accounts.map((account) => {
                                            return renderAccount(account);
                                        })}
                                    </tbody>
                                </table>
                            </Panel>
                        </div>
                    </Panel>
                </LayoutSettings>
            </Layout>
        );
    else {
        return (
            <Layout headerType={HeaderType.Settings}>
                <LayoutSettings>
                    <Notice
                        key="main"
                        title="Access denied"
                        message="You do not have permission to access this page. Please contact the administrator."
                        status="error"
                        closeable={false}
                        style={{backgroundColor: "white", width: "80%", margin: "10px auto"}}
                    ></Notice>
                </LayoutSettings>
            </Layout>
        );
    }
};

export default EntitySpecific;

declare type AddMemberPropTypes = {
    entity: Entity;
};

const AddMemberModal: React.FC<AddMemberPropTypes> = (props: AddMemberPropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const trg = useTr("global");
    const [email, setEmail] = React.useState<string>("");
    const [error, setError] = React.useState<boolean>(false);

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const onAddHandle = () => {
        setError(false);
        if (validateEmail(email) !== null) {
            fetchApi(`/entity/${props.entity.hash}/add_member/`, "post", {email: email})
                .then((response) => response.json())
                .then((data) => {
                    typeof data.error !== "undefined" ? addErrorNotification(data) : loadUserInfo();
                });
            setVisible(false);
            setEmail("");
        } else {
            setError(true);
        }
    };

    return (
        <>
            <Button kind="primary" className="float-end mt-3" compact onClick={() => setVisible(true)}>
                {trg("add_user")}
            </Button>
            <Modal
                visible={visible}
                title={`Add new user`}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "33.75rem"}}
                icons={""}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {trg("cancel")}
                        </Button>
                        <Button onClick={onAddHandle} kind="primary">
                            {trg("add_user")}
                        </Button>
                    </>
                }
            >
                <Input
                    type="text"
                    required={true}
                    value={email}
                    allowClear={true}
                    title="User email address"
                    onChange={(v) => setEmail(v)}
                    onClick={() => setError(false)}
                    error={error}
                    errorNode={
                        <div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">
                            {trg("please_enter_a_valid_email_adress")}{" "}
                        </div>
                    }
                />
            </Modal>
        </>
    );
};

declare type DeleteMemberPropTypes = {
    member: BackendUserInfo;
    entity: Entity;
};

const DeleteMemberModal: React.FC<DeleteMemberPropTypes> = (props: DeleteMemberPropTypes) => {
    const [visible, setVisible] = React.useState(false);
    const trg = useTr("global");

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);

    const onDeleteHandle = (email: string) => {
        setVisible(false);
        fetchApi(`/entity/${props.entity.hash}/delete_member/`, "delete", {email: email})
            .then((res) => res.json())
            .then((data) => {
                typeof data.error !== "undefined" ? addErrorNotification(data) : loadUserInfo();
            });
    };

    return (
        <>
            <span
                className="Appkit4-icon icon-delete-fill ap-font-24"
                aria-hidden="true"
                style={{color: "#D04A02", cursor: "pointer"}}
                onClick={() => setVisible(true)}
            ></span>
            <Modal
                visible={visible}
                title={`Delete user ${props.member.first_name} ${props.member.last_name}?`}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "33.75rem"}}
                icons={""}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="tertiary" style={{marginRight: "10px"}}>
                            {trg("cancel")}
                        </Button>
                        <Button onClick={() => onDeleteHandle(props.member.email)} kind="negative">
                            {trg("yes_delete")}
                        </Button>
                    </>
                }
            >
                <div>
                    {trg("are_you_sure_you_really")} <Badge type="danger-outlined" value={props.member.email}></Badge>{" "}
                    {trg("as")}&nbsp;{trg("a")}&nbsp;{trg("user_of_this_entity")}
                </div>
            </Modal>
        </>
    );
};

declare type EntityFornPropTypes = {
    entity: Entity;
};

const EntityFormModal: React.FC<EntityFornPropTypes> = (props: EntityFornPropTypes) => {
    const [name, setName] = React.useState<string>(props.entity.name);
    const [street, setStreet] = React.useState<string>(props.entity.street);
    const [streetNumber, setStreetNumber] = React.useState<string>(props.entity.street_number);
    const [city, setCity] = React.useState<string>(props.entity.city);
    const [zipCode, setZipCode] = React.useState<string>(props.entity.zip_code);
    const [country, setCountry] = React.useState<string>();
    const [idNumber, setIdNumber] = React.useState<string>(props.entity.id_number);
    const [taxNumber, setTaxNumber] = React.useState<string>(props.entity.tax_number);
    const [ruleset, setRuleset] = React.useState<string>(
        typeof props.entity.ruleset === "number"
            ? props.entity.ruleset + ""
            : props.entity.ruleset
            ? props.entity.ruleset.id + ""
            : ""
    );

    const [error, setError] = React.useState<boolean>(false);
    const [buttonIsLoading, setButtonIsLoading] = React.useState<boolean>(false);

    const [countries, setCountries] = React.useState<any[]>([]);
    const [typesOfLegacy, setTypesOfLegacy] = React.useState<any[]>([]);

    const fetchApi = useGlobalStore((s) => s.fetchApi);
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const codebooks = useGlobalStore((s) => s.codebooks);
    const addErrorNotification = useGlobalStore((s) => s.addErrorNotification);
    const trg = useTr("global");

    const onSaveHandle = () => {
        setError(false);
        let are_data_validated = ("" + name).length > 1;
        let validated_data = {
            name: name,
            street: street,
            street_number: streetNumber,
            city: city,
            country: country,
            zip_code: zipCode,
            id_number: idNumber,
            tax_number: taxNumber,
            ruleset: ruleset,
        };
        if (are_data_validated === true) {
            setButtonIsLoading(true);
            setError(false);
            fetchApi(`/entity/${props.entity.hash}/`, "patch", validated_data)
                .then((response) => response.json())
                .then((data) => {
                    if (typeof data.error !== "undefined") {
                        setError(true);
                        addErrorNotification(data);
                    } else {
                        setError(false);
                        loadUserInfo();
                    }
                    setButtonIsLoading(false);
                });
        } else {
            setError(true);
        }
    };

    React.useEffect(() => {
        setCountries(
            Object.entries(country_keys).map((item) => {
                return {
                    key: item[0],
                    value: item[0],
                    label: item[1],
                };
            })
        );
        setTypesOfLegacy(
            Object.entries(codebooks.rulesets).map((item, index) => {
                return {
                    key: item[1].id + "",
                    value: item[1].id + "",
                    label: item[1].name,
                };
            })
        );
        setCountry("SK");
    }, []);

    return (
        <>
            <div className="container" style={{zIndex: 99999}}>
                {error && (
                    <Notice
                        key="main"
                        title={trg("please_fill_all_the_fields")}
                        status="error"
                        closeable={true}
                        style={{width: "100%"}}
                    ></Notice>
                )}
                <div className="row">
                    <div className="col-md-4 col-sm-6 mb-4">
                        <Input
                            type="text"
                            required={true}
                            value={name}
                            allowClear={true}
                            title={trg("entity_name")}
                            onChange={(v) => setName(stripHtml(v))}
                            onClick={() => setError(false)}
                        />
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                        <Input
                            type="text"
                            required={false}
                            value={street}
                            allowClear={true}
                            title={trg("street")}
                            onChange={(v) => setStreet(stripHtml(v))}
                            onClick={() => setError(false)}
                        />
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                        <Input
                            type="text"
                            required={false}
                            value={streetNumber}
                            title={trg("street_number")}
                            onChange={(v) => setStreetNumber(stripHtml(v))}
                            onClick={() => setError(false)}
                        />
                    </div>
                    <div className="col-md-5 col-sm-6 mb-4">
                        <Input
                            type="text"
                            required={false}
                            value={city}
                            title={trg("city")}
                            onChange={(v) => setCity(stripHtml(v))}
                            onClick={() => setError(false)}
                        />
                    </div>
                    <div className="col-md-4 col-sm-6 mb-4">
                        {countries.length > 0 && country && (
                            <Select
                                required={false}
                                data={countries}
                                value={country}
                                defaultValue={country}
                                placeholder={trg("country")}
                                searchable={true}
                                onSelect={(v: any) => setCountry(v)}
                                dropdownMatchWidth={false}
                            ></Select>
                        )}
                    </div>
                    <div className="col-md-3 col-sm-6 mb-4">
                        <Input
                            type="text"
                            required={false}
                            value={zipCode}
                            title={trg("zip_code")}
                            onChange={(v: any) => setZipCode(stripHtml(v))}
                            onClick={() => setError(false)}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-4">
                        <Input
                            type="text"
                            required={false}
                            value={idNumber}
                            title={trg("id_number")}
                            onChange={(v) => setIdNumber(stripHtml(v))}
                            onClick={() => setError(false)}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-4">
                        <Input
                            type="text"
                            required={false}
                            value={taxNumber}
                            title={trg("tax_number")}
                            onChange={(v) => setTaxNumber(stripHtml(v))}
                            onClick={() => setError(false)}
                        />
                    </div>
                    <div className="col-md-3 col-sm-6 mb-4">
                        {typesOfLegacy.length > 0 && (
                            <Select
                                required={false}
                                value={ruleset.toString()}
                                data={typesOfLegacy}
                                placeholder={trg("type_of_legacy")}
                                onSelect={(v: any) => setRuleset(v)}
                                dropdownMatchWidth={false}
                            ></Select>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-10"></div>
                    <div className="col-2">
                        <Button onClick={onSaveHandle} kind="primary" loading={buttonIsLoading}>
                            {trg("save")}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

declare type EditAccountPropTypes = {
    account?: Account;
    onSaveHandler?: () => void;
    entity: Entity;
};

const EditAccountForm: React.FC<EditAccountPropTypes> = (props: EditAccountPropTypes) => {
    const [visible, setVisible] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [rulesetForm, setRulesetForm] = React.useState<Account | undefined>(
        props.account ? props.account : undefined
    );
    const loadUserInfo = useGlobalStore((s) => s.loadUserInfo);
    const trg = useTr("global");

    const [data, setData] = React.useState<Account>({
        id: props.account ? props.account.id : 0,
        name: props.account ? props.account.name : "",
        number: props.account ? props.account.number : "",
        account_number_1: props.account ? props.account.account_number_1 : 0,
        account_number_2: props.account ? props.account.account_number_2 : 0,
    });

    const {fetchApi, addErrorNotification} = useGlobalStore((s) => ({
        fetchApi: s.fetchApi,
        addErrorNotification: s.addErrorNotification,
    }));

    const saveHandler = () => {
        setIsLoading(true);
        const endpoint = `/entity/${props.entity.hash}/account/${props.account?.id}/`;

        fetchApi(endpoint, "patch", data)
            .then((response) => response.json())
            .then((res) => {
                if (typeof res.error !== "undefined") {
                    addErrorNotification(res);
                } else {
                    if (typeof props.onSaveHandler !== "undefined") props.onSaveHandler();
                    setVisible(false);
                    loadUserInfo();
                }
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        setRulesetForm(props.account);
        setData({
            id: props.account ? props.account.id : 0,
            name: props.account ? props.account.name : "",
            number: props.account ? props.account.number : "",
            account_number_1: props.account ? props.account.account_number_1 : 0,
            account_number_2: props.account ? props.account.account_number_2 : 0,
        });
    }, [props.account]);

    return (
        <>
            {rulesetForm === undefined ? (
                <Button
                    add
                    icon="icon-plus-outline"
                    onClick={() => setVisible(true)}
                    style={{position: "fixed", bottom: 30, right: 30}}
                >
                    {trg("add")}
                </Button>
            ) : (
                <Button className="d-inline p-1" onClick={() => setVisible(true)} kind={"text"} compact={true}>
                    <span className="Appkit4-icon icon-edit-outline"></span>
                </Button>
            )}
            <Modal
                visible={visible}
                title={`Account ID: ${rulesetForm ? rulesetForm.id : "-"}`}
                ariaLabel={"Ruleset Form"}
                onCancel={() => setVisible(false)}
                modalStyle={{width: "43.75rem"}}
                footerStyle={{paddingTop: "8px", marginTop: "-8px", minHeight: "64px"}}
                header={
                    rulesetForm ? (
                        <Badge type={"primary"} value="Editing"></Badge>
                    ) : (
                        <Badge type="success" value="Adding"></Badge>
                    )
                }
                icons={""}
                footer={
                    <>
                        <Button onClick={() => setVisible(false)} kind="secondary">
                            {trg("cancel")}
                        </Button>
                        <Button onClick={saveHandler} loading={isLoading}>
                            {trg("save")}
                        </Button>
                    </>
                }
                bodyStyle={{minHeight: "92px"}}
            >
                <div className="row" style={{paddingBottom: "80px"}}>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={trg("account_number")}
                            value={data.number}
                            required
                            onChange={(val: string) => setData((s) => ({...s, number: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={trg("account_name")}
                            value={data.name}
                            required
                            onChange={(val: string) => setData((s) => ({...s, name: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={trg("account_number_1")}
                            value={data.account_number_1}
                            onChange={(val: number) => setData((s) => ({...s, account_number_1: val}))}
                        ></Input>
                    </div>
                    <div className="col-md-8 mt-3">
                        <Input
                            type={"text"}
                            title={trg("account_number_2")}
                            value={data.account_number_2}
                            onChange={(val: number) => setData((s) => ({...s, account_number_2: val}))}
                        ></Input>
                    </div>
                </div>
            </Modal>
        </>
    );
};
