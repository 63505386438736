import React from "react";
import CanShowAdminMiddleware from "../../misc/CanShowAdminMiddleware";
import Layout from "../Layout/Layout";
import {HeaderType} from "../../types/HeaderType";
import {Button} from "@appkit4/react-components/button";
import {Panel} from "@appkit4/react-components/panel";
import {useNavigate} from "react-router";
import {useTr} from "../../utils/trUtil";

declare type PropTypes = {};

const AdminDashboard: React.FC<PropTypes> = (props: PropTypes) => {
    const navigate = useNavigate();
    const trg = useTr("global");

    return (
        <Layout headerType={HeaderType.Blank}>
            <CanShowAdminMiddleware>
                <h2>{trg("admin_dashboard")}</h2>
                <div className="row">
                    <div className="col-3">
                        <Panel title="Django Fixtures">
                            {trg("add_and_update_initial_data_as_ruleset")} <br />
                            <br />
                            <Button className="ms-auto" onClick={() => navigate(`/settings/fixtures`)}>
                                {trg("go")}
                            </Button>
                        </Panel>
                    </div>
                </div>
            </CanShowAdminMiddleware>
        </Layout>
    );
};

export default AdminDashboard;
